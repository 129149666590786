import React from 'react';
import { Button, Divider, Drawer } from 'antd';
import TerritorySelect from './TerritorySelect';
import ComparisonTerritorySelect from './ComparisonTerritorySelect';
import { Territory } from '../../../shared/interfaces/territory.interface';
import { CloseOutlined, RightOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { State, useConfigurationStore } from '../../../store';

type Props = {
  visible: boolean;
  setVisible(val: boolean): void;
};

const ConfigurationSider: React.FC<Props> = ({ visible, setVisible }) => {
  const [currentTerritory, setTerritory] = React.useState<Territory | undefined>(undefined);
  const [comparisonTerritory, setComparisonTerritory] = React.useState<number[]>([]);
  const setStateTerritory = useConfigurationStore((state: State) => state.setTerritory);
  const setStateComparisonTerritories = useConfigurationStore((state: State) => state.setComparisonTerritories);

  const stateTerritory = useConfigurationStore((state: State) => state.territory);
  const stateComparisonTerritories = useConfigurationStore((state: State) => state.comparisonTerritories);

  const onTerritoryClear = (): void => {
    setTerritory(undefined);
    setComparisonTerritory([]);
  };

  const isValid = () => typeof stateTerritory !== 'undefined' && stateComparisonTerritories.length;

  React.useEffect(() => {
    setTerritory(stateTerritory);
    setComparisonTerritory(stateComparisonTerritories);
  }, []);

  const closeDrawer = () => {
    if (isValid()) {
      setVisible(false);
    }
  };

  const handleTerritorySelect = (territory: Territory) => {
    if (territory !== currentTerritory) {
      setTerritory(territory);
    }
  };

  return (
    <Drawer
      onClose={closeDrawer}
      placement="left"
      visible={visible}
      closable={false}
      width={'25%'}
      className="side-drawer"
    >
      <div className="side-drawer-content-container">
        <div className="sider-stretch">
          <div className="sider-top">
            <Button shape="circle" icon={<CloseOutlined />} onClick={closeDrawer} disabled={!isValid()} />
          </div>
          <div className={`select-group ${!_.isNil(currentTerritory) ? 'selected' : ''}`}>
            <h3>Wybór gminy:</h3>
            <TerritorySelect onClear={onTerritoryClear} onSelect={handleTerritorySelect} />
          </div>
          {!_.isNil(currentTerritory) ? (
            <div className="territory-info">
              <div>
                <p>Liczba mieszkańców:</p>
                <span>{currentTerritory?.population ? currentTerritory?.population : '-'}</span>
              </div>
              <div>
                <p>Makroregion:</p>
                <span>{currentTerritory?.makroregion ? currentTerritory?.makroregion : '-'}</span>
              </div>
              <div>
                <p>Podregion:</p>
                <span>{currentTerritory?.podregion ? currentTerritory?.podregion : '-'}</span>
              </div>
              <div>
                <p>Województwo:</p>
                <span>{currentTerritory?.voivodeship_name ? currentTerritory?.voivodeship_name : '-'}</span>
              </div>
              <div>
                <p>Powiat:</p>
                <span>{currentTerritory?.powiat_name ? currentTerritory?.powiat_name : '-'}</span>
              </div>
            </div>
          ) : null}
          <div className={`select-group ${comparisonTerritory.length ? 'selected' : ''}`}>
            <Divider />
            <h3>Dobór gmin do porównania:</h3>
            <ComparisonTerritorySelect
              onSelect={setComparisonTerritory}
              territory={currentTerritory}
              value={comparisonTerritory || []}
            />
          </div>
        </div>
        <div className="sider-controls">
          <Button
            className="controls-accept"
            disabled={typeof currentTerritory == 'undefined' && !comparisonTerritory.length}
            onClick={() => {
              setStateTerritory(currentTerritory);
              setStateComparisonTerritories(comparisonTerritory);
              setVisible(false);
            }}
          >
            Zastosuj <RightOutlined />
          </Button>
          <Button className="controls-cancel" onClick={closeDrawer} disabled={!isValid()}>
            Anuluj <CloseOutlined />
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default ConfigurationSider;
