import React from 'react';
import { Select } from 'antd';
import _ from 'lodash';
import { useQuery } from 'react-query';
import { fetchIndicatorAreas } from '../../../queries';
import { IndicatorArea } from '../../../shared/interfaces/indicators.interface';
import { sortByLabel } from '../../../shared/utils';

const { Option } = Select;

type Props = {
  onSelect(value: IndicatorArea | undefined): void;
  active?: boolean;
  labelBold: boolean;
  clearFields(arr: string[]): void;
};

const AreaSelect: React.FC<Props> = ({ onSelect, active, labelBold, clearFields }) => {
  const { isLoading, data } = useQuery(['areas'], () => fetchIndicatorAreas(), {});

  return (
    <Select
      allowClear={true}
      placeholder="Wybierz obszar"
      optionFilterProp="children"
      filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      onChange={(key) => onSelect(_.find(data, ['key', key]))}
      loading={isLoading}
      className={`nav-select ${active ? 'active' : ''} ${labelBold ? 'label-bold' : ''}`}
      dropdownClassName="select-dropdown"
      showArrow={false}
      showSearch={true}
      onSelect={() => clearFields(['subArea', 'indicator'])}
    >
      {sortByLabel(data || []).map((row: IndicatorArea) => (
        <Option key={row.key} value={row.key}>
          {row.label}
        </Option>
      ))}
    </Select>
  );
};

AreaSelect.defaultProps = {
  active: false,
};

export default AreaSelect;
