import React, { useMemo } from 'react';
import { State, useConfigurationStore } from '../../store';
import { useQuery } from 'react-query';
import { fetchSubareaStandardizedValue } from '../../queries';
import { useParams } from 'react-router-dom';
import StandardizedValueChart from '../../shared/components/StandarizedValueChart';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import CopyWarningIcon from '../../shared/components/CopyWarningIcon/CopyWarningIcon';

const titleText = 'Wskaźnik rozwoju podobszaru';

export default function StandarizedValueChart(areaName?: { areaName: string | undefined }) {
  const { id } = useParams<{ id: string }>();
  const territory = useConfigurationStore((state: State) => state.territory);
  const year = useConfigurationStore((state: State) => state.year);
  const { isLoading, data } = useQuery(
    ['fetchSubareaStandardizedValue', id, territory, year],
    fetchSubareaStandardizedValue,
    {
      enabled: !!territory,
    },
  );

  const title = useMemo(
    () => (
      <React.Fragment>
        <p>{titleText}</p>
        <Tooltip
          title="Wskaźnik rozwoju podobszaru stanowi średnią arytmetyczną wartości z wszystkich wskaźników diagnostycznych wybranego podobszaru. Stan i zmiana wskaźnika w okresie 5 lat."
          placement="top"
        >
          <InfoCircleOutlined />
        </Tooltip>
        {data?.includes_copied_values ? <CopyWarningIcon /> : null}
      </React.Fragment>
    ),
    [data],
  );

  return (
    <StandardizedValueChart
      data={data?.data}
      label={territory?.label || ''}
      isLoading={isLoading}
      title={title}
      dimension={areaName?.areaName}
    />
  );
}
