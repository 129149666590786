import React, { useEffect } from 'react';
import { Select } from 'antd';
import _ from 'lodash';
import { useQuery, useQueryClient } from 'react-query';
import { fetchIndicators } from '../../../queries';
import { Indicator, IndicatorSubarea } from '../../../shared/interfaces/indicators.interface';
import { sortByLabel } from '../../../shared/utils';

const { Option } = Select;

type Props = {
  onSelect(value: Indicator | undefined): void;
  subArea: IndicatorSubarea | undefined;
  indicator: Indicator | undefined;
  active: boolean;
  labelBold: boolean;
};

const IndicatorSelect: React.FC<Props> = ({ onSelect, subArea, indicator, active, labelBold }) => {
  const [selected, setSelected] = React.useState<undefined | number>(undefined);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (indicator === undefined) {
      setSelected(undefined);
      queryClient.invalidateQueries('indicators');
    }
  }, [subArea, indicator, queryClient]);

  const { isLoading, data } = useQuery(['indicators', subArea], fetchIndicators, {
    enabled: !!subArea,
  });

  return (
    <Select
      loading={isLoading}
      allowClear={true}
      value={selected}
      placeholder="Wybierz wskaźnik"
      optionFilterProp="children"
      showSearch={true}
      filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      onChange={(key: number) => {
        setSelected(key);
        onSelect(_.find(data, ['key', key]));
      }}
      className={`nav-select ${active ? 'active' : ''} ${labelBold ? 'label-bold' : ''}`}
      dropdownClassName="select-dropdown"
      showArrow={false}
    >
      {sortByLabel(data || []).map((row: Indicator) => (
        <Option key={row.key} value={row.key}>
          {row.label}
        </Option>
      ))}
    </Select>
  );
};

IndicatorSelect.defaultProps = {
  active: false,
};

export default IndicatorSelect;
