import React from 'react';
import { useParams } from 'react-router-dom';
import { State, useConfigurationStore } from '../../store';
import { useQuery } from 'react-query';
import { fetchSubareaIndicatorDynamics } from '../../queries';
import DynamicsChart from '../../shared/components/DynamicsChart';
import { ENABLE_PARTNERSHIPS } from '../../config';

export default function IndicatorDynamicsChart(areaName?: { areaName: string | undefined }) {
  const { id } = useParams<{ id: string }>();

  const territory = useConfigurationStore((state: State) => state.territory);
  const partnership = useConfigurationStore((state: State) => state.partnership);
  const reference_territory = ENABLE_PARTNERSHIPS ? partnership : territory;

  const year = useConfigurationStore((state: State) => state.year);
  const { isLoading, data } = useQuery(
    ['fetchSubareaIndicatorDynamics', id, reference_territory, year],
    fetchSubareaIndicatorDynamics,
    {
      enabled: !!reference_territory && !!id,
    },
  );

  const title = 'Rozkład wartości dla wskaźników w podobszarze';
  return (
    <DynamicsChart
      title={title}
      data={data?.data}
      isLoading={isLoading}
      linkUrl="/indicators/"
      titleText={title}
      areaName={areaName}
      showLegend={false}
      showCompGroup={false}
    />
  );
}
