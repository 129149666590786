import React from 'react';
import { useParams } from 'react-router-dom';
import { State, useConfigurationStore } from '../../store';
import { useQuery } from 'react-query';
import { fetchSubareaGminaDynamics } from '../../queries';
import DynamicsChart from '../../shared/components/DynamicsChart';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { ENABLE_PARTNERSHIPS } from '../../config';

export default function GminaDynamicsChart(areaName?: { areaName: string | undefined }) {
  const { id } = useParams<{ id: string }>();

  const territory = useConfigurationStore((state: State) => state.territory);
  const year = useConfigurationStore((state: State) => state.year);
  const comparisonTerritories = useConfigurationStore((state: State) => state.comparisonTerritories);
  const { isLoading, data } = useQuery(
    ['fetchSubareaGminaDynamics', id, territory, comparisonTerritories, year],
    fetchSubareaGminaDynamics,
    {
      enabled: !!territory && !!id,
    },
  );

  const titleText = ` Pozycja ${ENABLE_PARTNERSHIPS ? 'gminy w partnerstwie' : 'gminy w grupie porównawczej'}`;
  const title = (
    <React.Fragment>
      {titleText}
      <Tooltip title="Stan i zmiana wskaźnika w okresie 5 lat" placement="top">
        <InfoCircleOutlined />
      </Tooltip>
    </React.Fragment>
  );

  return (
    <DynamicsChart
      title={title}
      data={data?.data}
      isLoading={isLoading}
      showTeryt
      titleText={titleText}
      areaName={areaName}
      showCompGroup={true}
    />
  );
}
