import { Col, Row } from 'antd';
import React from 'react';
import './homeModeSelect.scss';
import { IndicatorAreaDimension } from '../../../../shared/interfaces/indicators.interface';
import { ENABLE_PARTNERSHIPS } from '../../../../config';

type Props = {
  selected: IndicatorAreaDimension;
  setSelected(key: IndicatorAreaDimension): void;
};

interface Dimension {
  key: IndicatorAreaDimension;
  label: string;
}

const HomeModeSelect: React.FC<Props> = ({ selected, setSelected }) => {
  const dimensions = [
    { key: 'OVERALL', label: `Ogólny Wskaźnik Rozwoju ${ENABLE_PARTNERSHIPS ? 'Partnerstwa' : 'Gminy'}` },
    { key: 'ECONOMIC', label: 'Wymiar Gospodarczy' },
    { key: 'SOCIAL', label: 'Wymiar Społeczny' },
    { key: 'ENVIRONMENTAL', label: 'Wymiar Środowiskowy' },
  ] as Dimension[];

  return (
    <Row gutter={[30, 10]}>
      {dimensions.map(({ key, label }) => (
        <Col key={key} onClick={() => setSelected(key)} className="mode-select-col">
          {' '}
          <span className={key === selected ? 'active' : ''}> {label} </span>{' '}
        </Col>
      ))}
    </Row>
  );
};

export default HomeModeSelect;
