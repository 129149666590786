import React from 'react';
import Plot from 'react-plotly.js';
import _ from 'lodash';
import { BubbleChartDataRow } from '../../interfaces/indicators.interface';
import { State, useConfigurationStore } from '../../../store';
import { ENABLE_PARTNERSHIPS } from '../../../config';
import moment from 'moment';

interface Props {
  data?: BubbleChartDataRow[];
  showLegend?: boolean;
  showCompGroup?: boolean;
  precision?: number;
}

export default function DynamicBubbleChart({ data, showLegend, showCompGroup, precision = 2 }: Props) {
  const now = moment().format('YYYY-MM-DD HH:mm');
  const annontation = ENABLE_PARTNERSHIPS ? `MRL partnerstwa ${now}` : `MRL gminy ${now}`;

  const territory = useConfigurationStore((state: State) => state.territory);
  const group_names = [
    { name: 'A0_MNPP: Warszawa', short_name: 'A0_MNPP: Warszawa' },
    { name: 'A1_MNPP: Rdzenie OF ośrodków wojewódzkich', short_name: 'A1_MNPP: Rdzenie OF wojew.' },
    { name: 'B2_GM: Strefy zewnętrzne OF ośrodków wojewódzkich', short_name: 'B2_GM: Strefy zewn. OF wojew.' },
    { name: 'B3_GMW: Strefy zewnętrzne OF ośrodków wojewódzkich', short_name: 'B3_GMW: Strefy zewn. OF wojew.' },
    { name: 'B4_GW: Strefy zewnętrzne OF ośrodków wojewódzkich', short_name: 'B4_GW: Strefy zewn. OF wojew.' },
    { name: 'C1_MNPP: Rdzenie OF miast subregionalnych', short_name: 'C1_MNPP: Rdzenie OF subreg.' },
    { name: 'C2_GM: Rdzenie OF miast subregionalnych', short_name: 'C2_GM: Rdzenie OF subreg.' },
    { name: 'D2_GM: Strefy zewnętrzne OF miast subregionalnych', short_name: 'D2_GM: Strefy zewn. OF subreg.' },
    { name: 'D3_GMW: Strefy zewnętrzne OF miast subregionalnych', short_name: 'D3_GMW: Strefy zewn. OF subreg.' },
    { name: 'D4_GW: Strefy zewnętrzne OF miast subregionalnych', short_name: 'D4_GW: Strefy zewn. OF subreg.' },
    { name: 'E2_GM: Ośrodki wielofunkcyjne', short_name: 'E2_GM: Ośrodki wielofunkcyjne' },
    { name: 'E3_GMW: Ośrodki wielofunkcyjne', short_name: 'E3_GMW: Ośrodki wielofunkcyjne' },
    { name: 'F3_GMiW: Rozwinięta funkcja transportowa', short_name: 'F3_GMiW: Rozwinięta f. transportowa' },
    { name: 'F4_GW: Rozwinięta funkcja transportowa', short_name: 'F4_GW: Rozwinięta f. transportowa' },
    { name: 'G2_GM: Funkcje pozarolnicze', short_name: 'G2_GM: F. pozarolnicze' },
    { name: 'G3_GMW: Funkcje pozarolnicze', short_name: 'G3_GMW: F. pozarolnicze' },
    { name: 'G4_GW: Funkcje pozarolnicze', short_name: 'G4_GW: F. pozarolnicze' },
    { name: 'H3_GMiW: Intensywna funkcja rolnicza', short_name: 'H3_GMiW: Intensywna f. rolnicza' },
    { name: 'H4_GW: Intensywna funkcja rolnicza', short_name: 'H4_GW: Intensywna f. rolnicza' },
    { name: 'I2_GM: Umiarkowana funkcja rolnicza', short_name: 'I2_GM: Umiarkowana f. rolnicza' },
    { name: 'I3_GMW: Umiarkowana funkcja rolnicza', short_name: 'I3_GMW: Umiarkowana f. rolnicza' },
    { name: 'I4_GW: Umiarkowana funkcja rolnicza', short_name: 'I4_GW: Umiarkowana f. rolnicza' },
    { name: 'J2_GM: Gospodarka ekstensywna', short_name: 'J2_GM: Gosp. ekstensywna' },
    { name: 'J3_GMW: Gospodarka ekstensywna', short_name: 'J3_GMW: Gosp. ekstensywna' },
    { name: 'J4_GW: Gospodarka ekstensywna', short_name: 'J4_GW: Gosp. ekstensywna' },
  ];

  if (!data) {
    return <div />;
  }

  function get_short_comp_group_name(el: BubbleChartDataRow) {
    const gr_name = showCompGroup
      ? el.fullname + ' - ' + group_names.find((group) => group.name === el.comparison_group)?.short_name
      : el.fullname;
    return gr_name;
  }

  function shorten_name(el: BubbleChartDataRow) {
    if (!isNaN(+el.name[0])) {
      return el.name.split(' ')[0];
    }
    return el.name;
  }

  let largeCircles = {
    x: _.map(data, (row) => (territory?.code === row.code ? row.value : null)),
    y: _.map(data, (row) => (territory?.code === row.code ? row.dynamic : null)),
    marker: {
      size: 40,
      color: '#0aba9d',
      opacity: 0.7,
      symbol: 'circle',
    },
    name: 'Trace 1',
  };
  let smallCircles = {
    x: _.map(data, (row) => (territory?.code !== row.code ? row.value : null)),
    y: _.map(data, (row) => (territory?.code !== row.code ? row.dynamic : null)),
    marker: {
      size: 30,
      color: '#1890ff',
      opacity: 0.7,
      symbol: 'circle',
    },
    name: 'Trace 0',
  };
  if (data[0] && 'included_in_synthetic' in data[0]) {
    largeCircles = {
      x: _.map(data, (row) => (row.included_in_synthetic ? row.value : null)),
      y: _.map(data, (row) => (row.included_in_synthetic ? row.dynamic : null)),
      marker: {
        size: 40,
        color: '#8cc152',
        opacity: 0.7,
        symbol: 'diamond',
      },
      name: 'Wskaźnik syntetyczny',
    };
    smallCircles = {
      x: _.map(data, (row) => (row.included_in_synthetic ? null : row.value)),
      y: _.map(data, (row) => (row.included_in_synthetic ? null : row.dynamic)),
      marker: {
        size: 30,
        color: '#f6BB42',
        opacity: 0.7,
        symbol: 'diamond',
      },
      name: 'Wskaźnik diagnostyczny',
    };
  }

  return (
    <Plot
      className="plot__fullsize"
      data={[
        {
          mode: 'text+markers',
          ...smallCircles,
          text: _.map(data, shorten_name),
          customdata: ENABLE_PARTNERSHIPS ? _.map(data, get_short_comp_group_name) : _.map(data, 'fullname'),
          hovertemplate: `%{customdata}
<br>Wartość: %{x:.${precision}f}<br>Dynamika: %{y:.${precision}f}<extra></extra>`,
          type: 'scatter',
        },
        {
          mode: 'text+markers',
          ...largeCircles,
          text: _.map(data, shorten_name),
          customdata: ENABLE_PARTNERSHIPS ? _.map(data, get_short_comp_group_name) : _.map(data, 'fullname'),
          hovertemplate: `%{customdata}\n<br>Wartość: %{x:.${precision}f}<br>Dynamika: %{y:.${precision}f}<extra></extra>`,
          type: 'scatter',
        },
      ]}
      layout={{
        autosize: true,
        width: 800,
        height: 480,
        legend: {
          xanchor: 'center',
          y: -0.17,
          x: 0.5,
          orientation: 'h',
        },
        showlegend: showLegend || false,
        margin: { t: 20, b: 55 },
        hovermode: 'closest',
        xaxis: {
          title: { text: `Wartość <br><i>${annontation}</i>` },
          showspikes: false,
        },
        yaxis: { title: 'Zmiana', automargin: true },
      }}
    />
  );
}
