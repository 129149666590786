import React, { useEffect } from 'react';
import { Select } from 'antd';
import _ from 'lodash';
import { useQuery, useQueryClient } from 'react-query';
import { fetchIndicatorSubAreas } from '../../../queries';
import { IndicatorArea, IndicatorSubarea } from '../../../shared/interfaces/indicators.interface';
import { sortByLabel } from '../../../shared/utils';

const { Option } = Select;

type Props = {
  onSelect(val: IndicatorSubarea | undefined): void;
  area: IndicatorArea | undefined;
  subArea: IndicatorSubarea | undefined;
  active: boolean;
  labelBold: boolean;
  clearFields(arr: string[]): void;
};

const SubareaSelect: React.FC<Props> = ({ onSelect, area, subArea, active, labelBold, clearFields }) => {
  const [selected, setSelected] = React.useState<number | undefined>(undefined);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (typeof subArea === 'undefined') {
      setSelected(undefined);
      queryClient.invalidateQueries('subareas');
    }
  }, [area, queryClient, subArea]);

  const { isLoading, data } = useQuery(['subareas', area], fetchIndicatorSubAreas, {
    enabled: !!area,
  });

  return (
    <Select
      loading={isLoading}
      onSelect={() => clearFields(['indicator'])}
      allowClear={true}
      value={selected}
      placeholder="Wybierz podobszar"
      optionFilterProp="children"
      showSearch={true}
      filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      onChange={(key) => {
        setSelected(key);
        onSelect(_.find(data, ['key', key]));
      }}
      className={`nav-select ${active ? 'active' : ''} ${labelBold ? 'label-bold' : ''}`}
      dropdownClassName="select-dropdown"
      showArrow={false}
    >
      {sortByLabel(data || []).map((row) => (
        <Option key={row.key} value={row.key}>
          {row.label}
        </Option>
      ))}
    </Select>
  );
};

SubareaSelect.defaultProps = {
  active: false,
};

export default SubareaSelect;
