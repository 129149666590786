import React from 'react';
import { Button, Drawer } from 'antd';
import TerritorySelect from './TerritorySelect';
import ComparisonTerritorySelect from './ComparisonTerritorySelect';
import { Territory } from '../../../shared/interfaces/territory.interface';
import { CloseOutlined, RightOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { State, useConfigurationStore } from '../../../store';
import PartnershipSelect from './PartnershipSelect';

type Props = {
  visible: boolean;
  setVisible(val: boolean): void;
};

const PartnershipConfigurationSider: React.FC<Props> = ({ visible, setVisible }) => {
  const [currentPartnership, setPartnership] = React.useState<Territory | undefined>(undefined);
  const [currentTerritory, setTerritory] = React.useState<Territory | undefined>(undefined);
  const [comparisonTerritory, setComparisonTerritory] = React.useState<number[]>([]);

  const setStatePartnership = useConfigurationStore((state: State) => state.setPartnership);
  const setStateTerritory = useConfigurationStore((state: State) => state.setTerritory);
  const setStateComparisonTerritories = useConfigurationStore((state: State) => state.setComparisonTerritories);

  const statePartnership = useConfigurationStore((state: State) => state.partnership);
  const stateTerritory = useConfigurationStore((state: State) => state.territory);
  const stateComparisonTerritories = useConfigurationStore((state: State) => state.comparisonTerritories);

  const isValid = () => Boolean(stateTerritory && stateComparisonTerritories);

  React.useEffect(() => {
    setPartnership(statePartnership);
    setTerritory(stateTerritory);
    setComparisonTerritory(stateComparisonTerritories);
  }, []);

  const closeDrawer = () => {
    if (isValid()) {
      setVisible(false);
    }
  };

  const handlePartnershipSelect = (partnership: Territory) => {
    if (partnership !== currentPartnership) {
      setPartnership(partnership);
      setTerritory(undefined);
    }
  };

  return (
    <Drawer
      onClose={closeDrawer}
      placement="left"
      visible={visible}
      closable={false}
      width={'20%'}
      className="side-drawer"
    >
      <div className="side-drawer-content-container ">
        <div className="sider-top">
          <Button shape="circle" icon={<CloseOutlined />} onClick={closeDrawer} disabled={!isValid()} />
        </div>
        <div className={`select-group ${!_.isNil(currentTerritory) ? 'selected' : ''}`}>
          <h3>Wybór partnerstwa:</h3>
          <PartnershipSelect onSelect={handlePartnershipSelect} />
        </div>
        <div className={`select-group ${comparisonTerritory.length ? 'selected' : ''}`}>
          <h3>Skład partnerstwa:</h3>
          <ComparisonTerritorySelect
            onSelect={setComparisonTerritory}
            partnership={currentPartnership}
            value={comparisonTerritory || []}
          />
        </div>
        <div className={`select-group ${!_.isNil(currentTerritory) ? 'selected' : ''}`}>
          <h3>Wyróżniona gmina:</h3>
          <TerritorySelect partnership={currentPartnership} onSelect={setTerritory} value={currentTerritory} />
        </div>
        <div className="sider-controls">
          <Button
            className="controls-accept"
            disabled={!(currentPartnership && currentTerritory)}
            onClick={() => {
              setStatePartnership(currentPartnership);
              setStateTerritory(currentTerritory);
              setStateComparisonTerritories(comparisonTerritory);
              setVisible(false);
            }}
          >
            Zastosuj <RightOutlined />
          </Button>
          <Button className="controls-cancel" onClick={closeDrawer} disabled={!isValid()}>
            Anuluj <CloseOutlined />
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default PartnershipConfigurationSider;
