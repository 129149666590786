import { Territory } from './shared/interfaces/territory.interface';
import axios from 'axios';
import { QueryFunctionContext } from 'react-query';
import CityComparison from './shared/interfaces/cityComparison.interface';
import {
  AreaDynamics,
  AreaValue,
  Indicator,
  IndicatorArea,
  IndicatorAreaDimension,
  IndicatorData,
  IndicatorDynamics,
  IndicatorSubarea,
  MainDynamics,
  PotentiallyCopiedValues,
  StandardizedValueDataRow,
  SubareaDynamics,
} from './shared/interfaces/indicators.interface';

export const api_url = '/api/';

export const fetchTerritories = async (): Promise<Territory[]> => {
  const res = await axios.get(`${api_url}gmina/`).catch((e) => {
    throw Error(e.message);
  });
  return res.data;
};

export const fetchTerritoryComparisonGroup = async ({ queryKey }: QueryFunctionContext): Promise<Territory[]> => {
  const id = queryKey[1] as number;
  const res = await axios.get(`${api_url}gmina/${id}/comparison_group`).catch((e) => {
    throw Error(e.message);
  });
  return res.data;
};

export const fetchIndicatorAreas = async (): Promise<IndicatorArea[]> => {
  const res = await axios.get(`${api_url}indicator_area/`).catch((e) => {
    throw Error(e.message);
  });
  return res.data;
};

export const fetchIndicatorSubAreas = async ({ queryKey }: QueryFunctionContext) => {
  const area = queryKey[1] as IndicatorArea;
  const res = await axios.get<IndicatorSubarea[]>(`${api_url}indicator_subarea/?area=${area.key}`).catch((e) => {
    throw Error(e.message);
  });
  return res.data;
};

export const fetchIndicators = async ({ queryKey }: QueryFunctionContext): Promise<Indicator[]> => {
  const subArea = queryKey[1] as IndicatorSubarea;
  const res = await axios.get(`${api_url}indicator/?subarea=${subArea.key}`).catch((e) => {
    throw Error(e.message);
  });
  return res.data;
};

export const fetchCityComparisons = async (): Promise<CityComparison[]> => {
  const res = await axios.get(`${api_url}city_comparison/`).catch((e) => {
    throw Error(e.message);
  });
  return res.data;
};

export const fetchIndicatorDetails = async (indicatorId: number): Promise<Indicator> =>
  axios.get(`${api_url}indicator/${indicatorId}`).then((res) => res.data);

export const fetchAreaDetails = async (areaId: number): Promise<IndicatorArea> =>
  axios.get(`${api_url}indicator_area/${areaId}`).then((res) => res.data);

export const fetchSubareaDetails = async (subareaId: number): Promise<IndicatorSubarea> =>
  axios.get(`${api_url}indicator_subarea/${subareaId}`).then((res) => res.data);

export const fetchIndicatorData = async ({ queryKey }: QueryFunctionContext) => {
  const indicator = queryKey[1] as number;
  const territory = queryKey[2] as Territory;
  const year = queryKey[3] as number;
  const res = await axios
    .get<IndicatorData>(`${api_url}indicator/${indicator}/data/?territory_id=${territory.key}&year=${year}`)
    .catch((e) => {
      throw Error(e.message);
    });
  return res.data;
};

export const fetchIndicatorStandardizedValue = async ({ queryKey }: QueryFunctionContext) => {
  const id = queryKey[1] as number;
  const territory = queryKey[2] as Territory;
  const year = queryKey[3] as number;

  const res = await axios
    .get<
      PotentiallyCopiedValues<StandardizedValueDataRow[]>
    >(`${api_url}indicator/${id}/standardized_value?territory_id=${territory.key}&year=${year}`)
    .catch((e) => {
      throw Error(e.message);
    });
  return res.data;
};

export const fetchIndicatorDynamics = async ({ queryKey }: QueryFunctionContext) => {
  const indicator = queryKey[1] as number;
  const territory = queryKey[2] as Territory;
  const comparisonTerritories = queryKey[3] as number[];
  const year = queryKey[4] as number;

  const res = await axios
    .post<PotentiallyCopiedValues<IndicatorDynamics[]>>(`${api_url}indicator/${indicator}/dynamics/`, {
      year,
      territory_id: territory.key,
      comparison_territories: comparisonTerritories,
    })
    .catch((e) => {
      throw Error(e.message);
    });
  return res.data;
};

export const fetchMainValue = async ({ queryKey }: QueryFunctionContext) => {
  const territory = queryKey[1] as Territory;
  const year = queryKey[2] as number;
  const dimension = queryKey[3] as IndicatorAreaDimension;

  const params = {
    year,
    territory_id: territory.key,
    dimension: dimension !== 'OVERALL' ? dimension : undefined,
  };

  const res = await axios
    .get<PotentiallyCopiedValues<StandardizedValueDataRow[]>>(`${api_url}main_value/`, { params })
    .catch((e) => {
      throw Error(e.message);
    });
  return res.data;
};

export const fetchDynamicsValue = async ({ queryKey }: QueryFunctionContext) => {
  const territory = queryKey[1] as Territory;
  const comparisonTerritories = queryKey[2] as number[];
  const year = queryKey[3] as number;
  const dimension = queryKey[4] as IndicatorAreaDimension;

  const res = await axios
    .post<PotentiallyCopiedValues<MainDynamics[]>>(`${api_url}main_dynamics_value/`, {
      year,
      territory_id: territory.key,
      comparison_territories: comparisonTerritories,
      dimension: dimension !== 'OVERALL' ? dimension : undefined,
    })
    .catch((e) => {
      throw Error(e.message);
    });
  return res.data;
};

export const fetchMainAreaValue = async ({
  queryKey,
}: QueryFunctionContext): Promise<PotentiallyCopiedValues<AreaValue[]>> => {
  const territory = queryKey[1] as Territory;
  const year = queryKey[2] as number;
  const dimension = queryKey[3] as IndicatorAreaDimension;

  const params = {
    year,
    territory_id: territory.key,
    dimension: dimension !== 'OVERALL' ? dimension : undefined,
  };

  const res = await axios
    .get<PotentiallyCopiedValues<AreaValue[]>>(`${api_url}main_area_value/`, { params })
    .catch((e) => {
      throw Error(e.message);
    });
  return res.data;
};

export const fetchSubareaStandardizedValue = async ({ queryKey }: QueryFunctionContext) => {
  const id = queryKey[1] as number;
  const territory = queryKey[2] as Territory;
  const year = queryKey[3] as number;

  const res = await axios
    .get<
      PotentiallyCopiedValues<StandardizedValueDataRow[]>
    >(`${api_url}indicator_subarea/${id}/standardized_value?territory_id=${territory.key}&year=${year}`)
    .catch((e) => {
      throw Error(e.message);
    });
  return res.data;
};

export const fetchSubareaGminaDynamics = async ({ queryKey }: QueryFunctionContext) => {
  const id = queryKey[1] as Territory;
  const territory = queryKey[2] as Territory;
  const comparisonTerritories = queryKey[3] as number[];
  const year = queryKey[4] as number;

  const res = await axios
    .post<PotentiallyCopiedValues<SubareaDynamics[]>>(`${api_url}indicator_subarea/${id}/gmina_dynamics/`, {
      year,
      territory_id: territory.key,
      comparison_territories: comparisonTerritories,
    })
    .catch((e) => {
      throw Error(e.message);
    });
  return res.data;
};

export const fetchSubareaIndicatorDynamics = async ({ queryKey }: QueryFunctionContext) => {
  const id = queryKey[1] as Territory;
  const territory = queryKey[2] as Territory;
  const year = queryKey[3] as number;

  const res = await axios
    .get<
      PotentiallyCopiedValues<SubareaDynamics[]>
    >(`${api_url}indicator_subarea/${id}/indicator_dynamics?territory_id=${territory.key}&year=${year}`)
    .catch((e) => {
      throw Error(e.message);
    });
  return res.data;
};

export const fetchAreaGminaDynamics = async ({ queryKey }: QueryFunctionContext) => {
  const id = queryKey[1] as Territory;
  const territory = queryKey[2] as Territory;
  const comparisonTerritories = queryKey[3] as number[];
  const year = queryKey[4] as number;

  const res = await axios
    .post<PotentiallyCopiedValues<AreaDynamics[]>>(`${api_url}indicator_area/${id}/gmina_dynamics/`, {
      year,
      territory_id: territory.key,
      comparison_territories: comparisonTerritories,
    })
    .catch((e) => {
      throw Error(e.message);
    });
  return res.data;
};

export const fetchAreaSubareaDynamics = async ({ queryKey }: QueryFunctionContext) => {
  const id = queryKey[1] as number;
  const territory = queryKey[2] as Territory;
  const year = queryKey[3] as number;

  const res = await axios
    .get<
      PotentiallyCopiedValues<AreaDynamics[]>
    >(`${api_url}indicator_area/${id}/subarea_dynamics/?territory_id=${territory.key}&year=${year}`)
    .catch((e) => {
      throw Error(e.message);
    });
  return res.data;
};

export const fetchAreaStandardizedValue = async ({ queryKey }: QueryFunctionContext) => {
  const id = queryKey[1] as number;
  const territory = queryKey[2] as Territory;
  const year = queryKey[3] as number;

  const res = await axios
    .get<
      PotentiallyCopiedValues<StandardizedValueDataRow[]>
    >(`${api_url}indicator_area/${id}/standardized_value?territory_id=${territory.key}&year=${year}`)
    .catch((e) => {
      throw Error(e.message);
    });
  return res.data;
};

export const fetchPartnerships = async (): Promise<Territory[]> =>
  axios.get(`${api_url}gmina/?type_name=partnership`).then((res) => res.data);

export const fetchPartnershipMembers = async ({ queryKey }: QueryFunctionContext): Promise<Territory[]> => {
  const id = queryKey[1] as number;

  return axios.get(`${api_url}gmina/partnership_members/?partnership_id=${id}`).then((res) => res.data);
};
