import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useConfigurationStore } from '../../../store';
import { ENABLE_PARTNERSHIPS } from '../../../config';

interface Props {
  queryParams?: { [key: string]: string };
}

const ExcelDownloadButton: React.FC<Props> = ({ queryParams }) => {
  const territory = useConfigurationStore((state) => state.territory);
  const partnership = useConfigurationStore((state) => state.partnership);
  const year = useConfigurationStore((state) => state.year);

  const referenceUnit = ENABLE_PARTNERSHIPS ? partnership : territory;

  const downloadUrl = () => {
    if (referenceUnit && year) {
      let url = `/api/table_export/?${ENABLE_PARTNERSHIPS ? 'partnershipId' : 'territoryId'}=${
        referenceUnit.key
      }&year=${year}`;
      if (queryParams) {
        const additionalParams = Object.entries(queryParams).map(([key, value]) => `&${key}=${value}`);
        url = url + additionalParams.join('');
        return url;
      }
    }
    return '#';
  };

  return (
    <Button
      className="downloadXlsxButton ant-col details_btn"
      type="primary"
      icon={<DownloadOutlined />}
      href={downloadUrl()}
      disabled={!(referenceUnit && year)}
    >
      Pobierz XLSX
    </Button>
  );
};

export default ExcelDownloadButton;
