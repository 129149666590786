import React from 'react';
import { Select } from 'antd';
import _ from 'lodash';
import { useQuery } from 'react-query';
import { fetchPartnerships } from '../../../queries';
import { Territory } from '../../../shared/interfaces/territory.interface';
import { sortByLabel } from '../../../shared/utils';

const { Option } = Select;

type Props = {
  onSelect(val: Territory | undefined): void;
};

export default function PartnershipSelect({ onSelect }: Props) {
  const { isLoading, data } = useQuery('fetchPartnerships', fetchPartnerships);

  return (
    <Select
      loading={isLoading}
      allowClear={true}
      showSearch
      placeholder="Wpisz nazwę partnerstwa"
      optionFilterProp="children"
      filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      onSelect={(key) => onSelect(_.find(data, ['key', key]))}
      onClear={() => {
        onSelect(undefined);
      }}
      dropdownClassName="config-dropdown"
    >
      {sortByLabel(data || []).map((row) => (
        <Option key={row.key} value={row.key}>
          {row.label}
        </Option>
      ))}
    </Select>
  );
}
