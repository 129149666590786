import React, { useMemo } from 'react';
import { Button, Divider, Drawer, Tooltip } from 'antd';
import { CloseOutlined, DownloadOutlined, InfoCircleTwoTone, WarningTwoTone } from '@ant-design/icons';
import moment from 'moment';
import { Indicator } from '../../interfaces/indicators.interface';
import { api_url } from '../../../queries';
import './indicatorInfo.scss';

type Props = {
  visible: boolean;
  setVisible(val: boolean): void;
  data?: Indicator;
};

const IndicatorInfoSider: React.FC<Props> = ({ visible, setVisible, data }) => {
  const closeDrawer = () => {
    setVisible(false);
  };

  const copiedDataWarningText: string = useMemo(() => {
    if (data && data.includes_copied_values && data.values_copied_from) {
      const text = `Dane z roku ${data.values_copied_from} zostały uzupełnione danymi z roku ${
        data.values_copied_from - 1
      }`;
      return text;
    }
    return '';
  }, [data]);

  return (
    <Drawer
      onClose={closeDrawer}
      placement="right"
      visible={visible}
      closable={false}
      width={'25%'}
      className="side-drawer"
      mask={false}
    >
      <div className="side-drawer-content-container indicatorInfo">
        <div className="sider-stretch">
          <div className="sider-top">
            <h2>Szczegóły wskaźnika</h2>
            {data?.includes_copied_values ? (
              <section className="warning">
                <Tooltip placement="bottom" title={copiedDataWarningText}>
                  <WarningTwoTone twoToneColor="#ff7875" />
                </Tooltip>
              </section>
            ) : null}
            <Button shape="circle" icon={<CloseOutlined />} onClick={closeDrawer} />
          </div>
          <Button
            className="downloadPDFButton details_btn"
            type="primary"
            href={`${api_url}indicator_export/?key=${data?.key}`}
            target="_blank"
            icon={<DownloadOutlined />}
          >
            Pobierz PDF
          </Button>
          <Divider className="divider" />
          <div>
            <h3>Nazwa:</h3>
            <p>{data?.name}</p>
          </div>
          {data?.includes_copied_values ? (
            <div className="info">
              <InfoCircleTwoTone />
              <p>{copiedDataWarningText}</p>
            </div>
          ) : null}
          <Divider className="divider" />
          <div>
            <h3>Nazwa skrocona:</h3>
            <p>{data?.short_name}</p>
          </div>
          <Divider className="divider" />
          <div>
            <h3>Obszar:</h3>
            <p>{data?.area}</p>
          </div>
          <Divider className="divider" />
          <div>
            <h3>Podobszar:</h3>
            <p>{data?.subarea_name}</p>
          </div>
          <Divider className="divider" />
          <div>
            <h3>Informacja:</h3>
            {window.location.hostname.includes('gminy') ? (
              <p>MRL Gminy</p>
            ) : window.location.hostname.includes('partnerstwa') ? (
              <p>MRL Partnerstwa</p>
            ) : window.location.hostname.includes('analizy') ? (
              <p>MRL Analizy</p>
            ) : (
              ''
            )}
          </div>
          <Divider className="divider" />
          <div>
            <h3>Opis wskażnika:</h3>
            <p>{data?.description}</p>
          </div>
          <Divider className="divider" />
          <div style={{ width: '100%' }}>
            <h3>Formula:</h3>
            <p id="formula">{data?.formula}</p>
          </div>
          <Divider className="divider" />
          <div>
            <h3>Miara:</h3>
            <p>{data?.measure}</p>
          </div>
          <Divider className="divider" />
          <div>
            <h3>Data ostatniej aktualizacji:</h3>
            <p>{moment(data?.updated_at).format('YYYY-MM-DD HH:mm:ss')}</p>
          </div>
          <Divider className="divider" />
          <div>
            <h3>Składniki:</h3>
            {data?.components.map((element, i) => {
              return (
                <div key={i}>
                  <p>
                    <b>Nazwa: </b>
                    {element.label}
                  </p>
                  <span>
                    <b>Żródlo: </b>
                    {element.sourceCubeType}
                  </span>
                  {element.sourceCubeParents != null &&
                    element.sourceCubeParents.map((parent, ind) => {
                      return <span key={ind}>{parent.name + ' -> '}</span>;
                    })}
                  {element.sourceCubeName}
                  {data.components.length > 1 && <Divider className="divider" dashed={true} />}
                </div>
              );
            })}
          </div>
          <Divider className="divider" />
          <div>
            <h3>Dostępność wskaźnika w latach:</h3>
            <p>{data?.years_availability.join(', ')}</p>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default IndicatorInfoSider;
