interface LabelSortItem {
  label: string;
}

export const sortByLabel = <T extends LabelSortItem>(items: T[]) => {
  items.sort((a, b) =>
    a.label.localeCompare(b.label, 'pl', {
      numeric: true,
      ignorePunctuation: true,
    }),
  );
  return items;
};

interface NameSortItem {
  name: string;
}

export const sortByName = <T extends NameSortItem>(items: T[]) => {
  items.sort((a, b) =>
    a.name.localeCompare(b.name, 'pl', {
      numeric: true,
      ignorePunctuation: true,
    }),
  );
  return items;
};
