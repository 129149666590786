import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { fetchSubareaDetails } from '../../queries';
import Title from '../../shared/components/Title';
import GminaDynamicsChart from './GminaDynamicsChart';
import IndicatorDynamicsChart from './IndicatorDynamicsChart';
import { ENABLE_PARTNERSHIPS } from '../../config';
import PartnershipChart from './PartnershipChart';
import StandarizedValueChart from './StandarizedValueChart';
import ExcelDownloadButton from '../../shared/components/ExcelDownloadButton';
import './subarea.scss';

export default function SubAreaView() {
  const { id } = useParams<{ id: string }>();
  const { data: subarea } = useQuery(['indicatorDetails', id], () => fetchSubareaDetails(parseInt(id, 10)));

  return (
    <>
      <div className="title-wrapper">
        <Title title={subarea ? subarea.name : ''} />
        <div className="ant-col ant-col-4 buttons">
          <ExcelDownloadButton queryParams={{ level: 'subarea', subareaId: id }} />
        </div>
      </div>
      {ENABLE_PARTNERSHIPS && <PartnershipChart areaName={subarea?.label + ' (Partnerstwo)'} />}
      <StandarizedValueChart areaName={subarea?.label} />
      <GminaDynamicsChart areaName={subarea?.label} />
      <IndicatorDynamicsChart areaName={subarea?.label} />
    </>
  );
}
