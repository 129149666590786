import React, { useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Input, Form, Popover, notification, Spin } from 'antd';
import axios from 'axios';
import { api_url } from '../../../queries';
import { useConfigurationStore } from '../../../store';

interface PopoverProps {
  onSuccess: () => void;
}

const RequestForm: React.FC<PopoverProps> = ({ onSuccess }) => {
  const [isLoading, setLoading] = useState(false);

  const territory = useConfigurationStore((state) => state.territory);

  const validateMessages = {
    required: 'Adres email jest wymagany',
    types: {
      email: 'Podaj poprawny adres email',
    },
  };

  const onFinish = async ({ email }: { email: string }) => {
    setLoading(true);
    try {
      await axios.post(`${api_url}report_request/`, { email, gmina: territory?.key });
      notification.success({
        message: 'Adres email wysłany.',
        key: 'reportRequestSuccess',
        onClick: () => {
          notification.close('reportRequestSuccess');
        },
        style: {
          cursor: 'pointer',
        },
        duration: 5,
      });
      onSuccess();
    } catch {
      notification.error({
        message: 'Błąd przy wysyłaniu adresu email.',
        key: 'reportRequestError',
        onClick: () => {
          notification.close('reportRequestError');
        },
        style: {
          cursor: 'pointer',
        },
        duration: 5,
      });
    }
    setLoading(false);
  };

  return (
    <div className="report-request-form-container">
      <p>
        Prosimy zostawić adres email, na który ma zostać wysłany raport dotyczący sytuacji społeczno-ekonomicznej gminy.
      </p>
      <p>
        Podając adres mailowy wyraża Pani/Pan zgodę na przesłanie raportu z Monitora Rozwoju Lokalnego przez ZMP.
        Podanie danych jest dobrowolne, ale konieczne do przesłania raportu. Jeżeli chce Pani/Pan wycofać zgodę należy
        wysłać wiadomość na adres: <a href="mailto:rodo@zmp.poznan.pl">rodo@zmp.poznan.pl</a>.
      </p>
      <Form validateMessages={validateMessages} onFinish={onFinish}>
        <Form.Item name="email" rules={[{ type: 'email', required: true }]}>
          <Input placeholder="Adres email" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" block htmlType="submit">
            {isLoading ? <Spin /> : 'Wyślij'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

const ReportRequestButton: React.FC = () => {
  const [openPopover, setOpenPopover] = useState(false);
  return (
    <Popover
      title="Pobierz raport"
      content={<RequestForm onSuccess={() => setOpenPopover(false)} />}
      trigger="click"
      visible={openPopover}
      onVisibleChange={(visible) => setOpenPopover(visible)}
    >
      <Button className="report-request-button" size="small" type="primary" icon={<DownloadOutlined />}>
        Pobierz raport
      </Button>
    </Popover>
  );
};

export default ReportRequestButton;
