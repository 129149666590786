import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { fetchAreaDetails } from '../../queries';
import Title from '../../shared/components/Title';
import StandarizedValueChart from './StandarizedValueChart';
import GminaDynamicsChart from './GminaDynamicsChart';
import SubareaDynamicsChart from './SubareaDynamicsChart';
import { ENABLE_PARTNERSHIPS } from '../../config';
import PartnershipValueChart from './PartnershipValueChart';
import ExcelDownloadButton from '../../shared/components/ExcelDownloadButton';
import './area.scss';

export default function AreaView() {
  const { id } = useParams<{ id: string }>();
  const { data: area } = useQuery(['fetchAreaDetails', id], () => fetchAreaDetails(parseInt(id, 10)));

  return (
    <>
      <div className="title-wrapper">
        <Title title={area ? area.name : ''} />
        <div className="ant-col ant-col-4 buttons">
          <ExcelDownloadButton queryParams={{ level: 'area', areaId: id }} />
        </div>
      </div>
      {ENABLE_PARTNERSHIPS && <PartnershipValueChart areaName={area?.label + ' (Partnerstwo)'} />}
      <StandarizedValueChart areaName={area?.label} />
      <GminaDynamicsChart areaName={area?.label} />
      <SubareaDynamicsChart areaName={area?.label} />
    </>
  );
}
