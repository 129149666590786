import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { State, useConfigurationStore } from '../../store';
import { useQuery } from 'react-query';
import { fetchAreaSubareaDynamics } from '../../queries';
import DynamicsChart from '../../shared/components/DynamicsChart';
import { ENABLE_PARTNERSHIPS } from '../../config';
import CopyWarningIcon from '../../shared/components/CopyWarningIcon/CopyWarningIcon';

export default function SubareaDynamicsChart(areaName?: { areaName: string | undefined }) {
  const { id } = useParams<{ id: string }>();

  const territory = useConfigurationStore((state: State) => state.territory);
  const partnership = useConfigurationStore((state: State) => state.partnership);
  const reference_territory = ENABLE_PARTNERSHIPS ? partnership : territory;

  const year = useConfigurationStore((state: State) => state.year);
  const { isLoading, data } = useQuery(
    ['fetchAreaSubareaDynamics', id, reference_territory, year],
    fetchAreaSubareaDynamics,
    {
      enabled: !!reference_territory && !!id,
    },
  );

  const titleText = 'Rozkład wartości dla wskaźników w obszarze';

  const title = useMemo(
    () => (
      <React.Fragment>
        <p>{titleText}</p>
        {data?.includes_copied_values ? <CopyWarningIcon /> : null}
      </React.Fragment>
    ),
    [data],
  );

  return (
    <DynamicsChart
      title={title}
      titleText={titleText}
      data={data?.data}
      linkUrl="/subareas/"
      isLoading={isLoading}
      areaName={areaName}
      showLegend={false}
    />
  );
}
