import { BubbleChartDataRow } from '../../interfaces/indicators.interface';
import { DecimalNumberFormatter } from '../../formatting';
import { Button, Table } from 'antd';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { api_url } from '../../../queries';
import { State, useConfigurationStore } from '../../../store';

type CellAlignment = 'left' | 'right' | 'center' | undefined;

interface Props {
  titleText: string;
  title?: string | ReactNode;
  data?: BubbleChartDataRow[];
  isLoading?: boolean;
  linkUrl?: string;
  showTeryt?: boolean;
  areaName?: { areaName: string | undefined };
  precision?: number;
}

export default function DynamicTable({
  data,
  isLoading = false,
  linkUrl,
  showTeryt = false,
  title,
  titleText,
  areaName,
  precision = 2,
}: Props) {
  const territory = useConfigurationStore((state: State) => state.territory);
  const renderName = (text: string, record: BubbleChartDataRow) =>
    linkUrl ? <Link to={linkUrl + record.id}>{text}</Link> : text;

  const columns = showTeryt
    ? [
        {
          title: 'Nazwa',
          dataIndex: 'fullname',
          render: renderName,
        },
        {
          title: 'Grupa Śleszyńskiego',
          dataIndex: 'comparison_group',
        },
        {
          title: 'TERYT',
          dataIndex: 'code',
        },
        {
          title: 'Wartość',
          dataIndex: 'value',
          render: (text: string) => DecimalNumberFormatter(text, precision),
          align: 'right' as CellAlignment,
        },
        {
          title: 'Zmiana',
          dataIndex: 'dynamic',
          render: (text: string) => DecimalNumberFormatter(text, precision),
          align: 'right' as CellAlignment,
        },
      ]
    : [
        {
          title: 'Nazwa',
          dataIndex: 'fullname',
          render: renderName,
        },
        {
          title: 'Wartość',
          dataIndex: 'value',
          render: (text: string) => DecimalNumberFormatter(text, precision),
          align: 'right' as CellAlignment,
        },
        {
          title: 'Zmiana',
          dataIndex: 'dynamic',
          render: (text: string) => DecimalNumberFormatter(text, precision),
          align: 'right' as CellAlignment,
        },
      ];

  function convertToSlug(Text: string) {
    Text = Text.replace('.', '_').toLowerCase();
    const from = 'ãàáäâęẽèéëêìíïîõòóöôùúüûñçćśłż·/,:;';
    const to = 'aaaaaeeeeeeiiiiooooouuuunccslz-----';
    for (let i = 0, l = from.length; i < l; i++) {
      Text = Text.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    return Text.replace(/ /g, '-').replace(/[^\w-]+/g, '');
  }

  const exportXlsx = () => {
    const sheet_name = typeof title === 'string' ? convertToSlug(title) : titleText;
    let file_name = typeof territory?.label === 'string' ? convertToSlug(territory?.label) : titleText;
    if (typeof areaName?.areaName === 'string') {
      file_name = file_name + ' - ' + convertToSlug(areaName?.areaName);
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        data: data,
        filename: file_name,
        sheet_name: sheet_name,
        columns: columns,
      }),
    };

    return fetch(`${api_url}chart_table_export/`, requestOptions)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', file_name);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => console.error(err));
  };

  const style = {
    width: '100%',
    marginTop: '20px',
  };

  return (
    <div style={style}>
      <div className="table-container">
        <Button size="small" onClick={exportXlsx}>
          Pobierz XLSX
        </Button>
      </div>
      <div className="table-container">
        <Table
          loading={isLoading}
          pagination={false}
          columns={columns}
          dataSource={data}
          bordered
          scroll={{ y: 400 }}
        />
      </div>
    </div>
  );
}
