import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Home from './components/Home';
import AreaView from './components/AreaView';
import SubAreaView from './components/SubAreaView';
import IndicatorView from './components/IndicatorView';

export const HOME_ROUTE = '/';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route exact path={HOME_ROUTE} component={Home} />
      <Route exact path="/areas/:id/" component={AreaView} />
      <Route exact path="/subareas/:id/" component={SubAreaView} />
      <Route exact path="/indicators/:id/" component={IndicatorView} />
      <Redirect to={HOME_ROUTE} />
    </Switch>
  );
};

export default Routes;
