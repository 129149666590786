import React, { useMemo } from 'react';
import { State, useConfigurationStore } from '../../store';
import { useQuery } from 'react-query';
import { fetchIndicatorStandardizedValue } from '../../queries';
import StandardizedValueChart from '../../shared/components/StandarizedValueChart';
import { useParams } from 'react-router-dom';
import CopyWarningIcon from '../../shared/components/CopyWarningIcon/CopyWarningIcon';
import { Indicator } from '../../shared/interfaces/indicators.interface';

interface Props {
  indicator?: Indicator | undefined;
}

export default function PartnershipChart({ indicator }: Props) {
  const { id } = useParams<{ id: string }>();
  const partnership = useConfigurationStore((state: State) => state.partnership);
  const year = useConfigurationStore((state: State) => state.year);
  const { isLoading, data } = useQuery(
    ['fetchIndicatorStandardizedValue', id, partnership, year],
    fetchIndicatorStandardizedValue,
    {
      enabled: !!partnership,
    },
  );

  const title = useMemo(
    () => (
      <React.Fragment>
        <p>{'Wartość wskaźnika w latach'}</p>
        {data?.includes_copied_values ? <CopyWarningIcon /> : null}
      </React.Fragment>
    ),
    [data],
  );
  return (
    <StandardizedValueChart
      data={data?.data}
      label={partnership?.label || ''}
      isLoading={isLoading}
      title={title}
      switchType={true}
      dimension={indicator?.label}
    />
  );
}
