import React, { useMemo } from 'react';
import IndicatorDetailsChart from './IndicatorDetailsChart';
import IndicatorDynamicsChart from './IndicatorDynamicsChart';
import Title from '../../shared/components/Title';
import { useQuery } from 'react-query';
import { fetchIndicatorDetails } from '../../queries';
import { useParams } from 'react-router-dom';
import { ENABLE_PARTNERSHIPS } from '../../config';
import PartnershipChart from './PartnershipChart';
import ExcelDownloadButton from '../../shared/components/ExcelDownloadButton';
import IndicatorInfoSider from '../../shared/components/IndicatorInfo';
import { Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { State, useConfigurationStore } from '../../store';

const IndicatorView: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: indicator } = useQuery(['indicatorDetails', id], () => fetchIndicatorDetails(parseInt(id, 10)));
  const territory = useConfigurationStore((state: State) => state.territory);
  const [visible, setVisible] = React.useState(false);

  // this is an ugly workaround for https://zmppoznan.atlassian.net/browse/CITY-294
  // to avoid implementing a generic solution for a one-off case
  const showWarning = useMemo(
    () =>
      territory &&
      territory.name === 'Jastrzębie-Zdrój' &&
      indicator &&
      [
        '2.1.3. Przeciętne miesięczne wynagrodzenie brutto',
        '3.1.2 Przeciętne miesięczne wynagrodzenie brutto (dane powiatowe)',
      ].includes(indicator.name),
    [territory, indicator],
  );
  const warningContent =
    'Od 2022 r. przeciętne miesięczne wynagrodzenia brutto dla Jastrzębia-Zdroju jest wartością szacunkową obliczoną prze ZMP ze względu na niepublikowanie jej przez GUS. Wartość tę obliczono na podstawie zmian wysokości wynagrodzenia w pozostałych miastach na prawach powiatu województwa śląskiego.';

  const showDrawer = () => {
    setVisible(true);
  };

  return (
    <>
      <div className="title-wrapper">
        <Title title={indicator ? indicator.name : ''} warningTooltipText={showWarning ? warningContent : undefined} />
        <div className="ant-col ant-col-4 buttons">
          <Button onClick={showDrawer} type="primary" className="ant-col details_btn" icon={<SearchOutlined />}>
            Szczególy
          </Button>
          <ExcelDownloadButton queryParams={{ level: 'indicator', indicatorId: id }} />
        </div>
        <IndicatorInfoSider visible={visible} setVisible={setVisible} data={indicator} />
      </div>
      {ENABLE_PARTNERSHIPS && <PartnershipChart indicator={indicator} />}
      <IndicatorDetailsChart indicator={indicator} />
      <IndicatorDynamicsChart />
    </>
  );
};

export default IndicatorView;
