import React, { useMemo } from 'react';
import { State, useConfigurationStore } from '../../store';
import { useQuery } from 'react-query';
import { fetchMainValue } from '../../queries';
import { IndicatorAreaDimension } from '../../shared/interfaces/indicators.interface';
import './indicatorView.shared.scss';
import StandardizedValueChart from '../../shared/components/StandarizedValueChart';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { ENABLE_PARTNERSHIPS } from '../../config';
import CopyWarningIcon from '../../shared/components/CopyWarningIcon/CopyWarningIcon';

interface Props {
  dimension: IndicatorAreaDimension;
}

export default function IndicatorMainChart({ dimension }: Props) {
  const territory = useConfigurationStore((state: State) => state.territory);
  const year = useConfigurationStore((state: State) => state.year);
  const { isLoading, data } = useQuery(['mainValue', territory, year, dimension], fetchMainValue, {
    enabled: !!territory,
  });
  const dimensions = [
    {
      key: 'OVERALL',
      label: `Wskaźnik rozwoju ${ENABLE_PARTNERSHIPS ? 'gminy' : ''} - ogólny`,
      tooltip:
        'Ogólny wskaźnik rozwoju gminy stanowi średnią arytmetyczną wartości z wszystkich wskaźników diagnostycznych w 12 badanych obszarach. Stan i zmiana wskaźnika w okresie 5 lat.',
    },
    {
      key: 'ECONOMIC',
      label: 'Wskaźnik rozwoju - wymiar gospodarczy',
      tooltip:
        'Ogólny wskaźnik rozwoju gminy stanowi średnią arytmetyczną wartości z wszystkich wskaźników diagnostycznych wymiaru gospodarczego w 12 badanych obszarach. Stan i zmiana wskaźnika w okresie 5 lat.',
    },
    {
      key: 'SOCIAL',
      label: 'Wskaźnik rozwoju - wymiar społeczny',
      tooltip:
        'Ogólny wskaźnik rozwoju gminy stanowi średnią arytmetyczną wartości z wszystkich wskaźników diagnostycznych wymiaru społecznego w 12 badanych obszarach. Stan i zmiana wskaźnika w okresie 5 lat.',
    },
    {
      key: 'ENVIRONMENTAL',
      label: 'Wskaźnik rozwoju - wymiar środowiskowy',
      tooltip:
        'Ogólny wskaźnik rozwoju gminy stanowi średnią arytmetyczną wartości z wszystkich wskaźników diagnostycznych wymiaru środowiskowego w 12 badanych obszarach. Stan i zmiana wskaźnika w okresie 5 lat.',
    },
  ];
  const blockTitle = dimensions.find((el) => el.key === dimension)?.label || dimension;
  const tooltip = dimensions.find((el) => el.key === dimension)?.tooltip || dimension;
  const title = useMemo(
    () => (
      <React.Fragment>
        {blockTitle}
        <Tooltip title={tooltip} placement="top">
          <InfoCircleOutlined />
        </Tooltip>
        {data?.includes_copied_values ? <CopyWarningIcon /> : null}
      </React.Fragment>
    ),
    [data],
  );

  if (isLoading || !data) {
    return null;
  }

  return (
    <StandardizedValueChart
      data={data.data}
      label={territory?.label || ''}
      isLoading={isLoading}
      title={title}
      dimension={dimension}
    />
  );
}
