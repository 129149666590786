import React, { ReactNode, useState } from 'react';
import { Card, Col, Row, Switch } from 'antd';
import './container.scss';

interface Props {
  title: string | ReactNode;
  chart: ReactNode;
  table: ReactNode;
}

export default function ChartContainer({ chart, table, title }: Props) {
  const [showTable, setShowTable] = useState(false);

  return (
    <div className="chart-container">
      <Card
        title={title}
        extra={
          <div className="toggleContainer">
            <span>Dane tabelaryczne</span>
            <Switch checked={showTable} onChange={() => setShowTable(!showTable)} />
          </div>
        }
      >
        <Row>
          <Col span={24}>{chart}</Col>
          {showTable && <Col span={24}>{table}</Col>}
        </Row>
      </Card>
    </div>
  );
}
