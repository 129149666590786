import React from 'react';
import 'antd/dist/antd.css';
import styles from './applayout.module.scss';
import { Col, Layout, Row } from 'antd';
import PageHeader from '../PageHeader';
import ConfigurationSider from '../ConfigurationSider';
import { ENABLE_PARTNERSHIPS } from '../../../config';
import PartnershipConfigurationSider from '../PartnershipConfigurationSider';

const { Header, Content } = Layout;

const AppLayout: React.FC = ({ children }) => {
  const [visible, setVisible] = React.useState(true);

  return (
    <Layout className="layout" style={{ minHeight: '100vh' }}>
      {ENABLE_PARTNERSHIPS ? (
        <PartnershipConfigurationSider setVisible={setVisible} visible={visible} />
      ) : (
        <ConfigurationSider setVisible={setVisible} visible={visible} />
      )}
      <Layout className={styles.appLayout}>
        <Header className={styles.mainHeader}>
          <Row>
            <Col span={24}>
              <PageHeader setVisible={setVisible} visible={visible} />
            </Col>
          </Row>
        </Header>
        <Content className={styles.mainContent}>
          <Row className="max-1800">
            <Col span={24}>
              <div className="layout-content-container">{children}</div>
            </Col>
            <Col span={24}>
              <div className="footer"></div>
            </Col>
          </Row>
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
