import React, { useMemo } from 'react';
import { State, useConfigurationStore } from '../../store';
import { useQuery } from 'react-query';
import { fetchMainAreaValue } from '../../queries';
import { AreaValue, IndicatorAreaDimension } from '../../shared/interfaces/indicators.interface';
import './indicatorView.shared.scss';
import { ENABLE_PARTNERSHIPS } from '../../config';
import ChartContainer from '../../shared/components/ChartContainer';
import { Table } from 'antd';
import _ from 'lodash';
import Plot from 'react-plotly.js';
import { Link } from 'react-router-dom';
import { NumberFormatter } from '../../shared/formatting';
import moment from 'moment';
import CopyWarningIcon from '../../shared/components/CopyWarningIcon/CopyWarningIcon';

interface Props {
  dimension: IndicatorAreaDimension;
  indicator?: string;
}

interface TableRow {
  key: number;
  name: string;
}

export default function IndicatorAreaDynamicsGroupedChart({ dimension }: Props) {
  const territory = useConfigurationStore((state: State) => state.territory);
  const partnership = useConfigurationStore((state: State) => state.partnership);

  const reference_territory = ENABLE_PARTNERSHIPS ? partnership : territory;

  const year = useConfigurationStore((state: State) => state.year);
  const { isLoading, data } = useQuery(
    ['fetchMainAreaValue', reference_territory, year, dimension],
    fetchMainAreaValue,
    {
      enabled: !!reference_territory,
    },
  );

  const getChart = (data: AreaValue[] | undefined) => {
    if (!data) {
      return <div />;
    }
    const yearsList = data?.map((area) => area.year);
    const years = yearsList?.filter((n, i) => yearsList.indexOf(n) === i) || [];

    const data1 = data.filter((item) => item.year === years[0]).sort((a, b) => (a.area.order > b.area.order ? 1 : -1));
    const xNames1 = data1.map((n) => `Obszar ${n.area.order}`);
    const indicatorValues1 = {
      x: xNames1,
      y: _.map(data1, 'value'),
    };
    const valuesStr1 = indicatorValues1.y.map((e) => (Math.round((e + Number.EPSILON) * 100) / 100).toFixed(2));

    const data2 = data.filter((item) => item.year === years[1]).sort((a, b) => (a.area.order > b.area.order ? 1 : -1));
    const xNames2 = data2.map((n) => `Obszar ${n.area.order}`);
    const indicatorValues2 = {
      x: xNames2,
      y: _.map(data2, 'value'),
    };
    const valuesStr2 = indicatorValues2.y.map((e) => (Math.round((e + Number.EPSILON) * 100) / 100).toFixed(2));

    const data3 = data.filter((item) => item.year === years[2]).sort((a, b) => (a.area.order > b.area.order ? 1 : -1));
    const xNames3 = data3.map((n) => `Obszar ${n.area.order}`);
    const indicatorValues3 = {
      x: xNames3,
      y: _.map(data3, 'value'),
    };
    const valuesStr3 = indicatorValues3.y.map((e) => (Math.round((e + Number.EPSILON) * 100) / 100).toFixed(2));

    const data4 = data.filter((item) => item.year === years[3]).sort((a, b) => (a.area.order > b.area.order ? 1 : -1));
    const xNames4 = data4.map((n) => `Obszar ${n.area.order}`);
    const indicatorValues4 = {
      x: xNames4,
      y: _.map(data4, 'value'),
    };
    const valuesStr4 = indicatorValues4.y.map((e) => (Math.round((e + Number.EPSILON) * 100) / 100).toFixed(2));

    const data5 = data.filter((item) => item.year === years[4]).sort((a, b) => (a.area.order > b.area.order ? 1 : -1));
    const xNames5 = data5.map((n) => `Obszar ${n.area.order}`);
    const indicatorValues5 = {
      x: xNames5,
      y: _.map(data5, 'value'),
    };
    const valuesStr5 = indicatorValues5.y.map((e) => (Math.round((e + Number.EPSILON) * 100) / 100).toFixed(2));

    const now = moment().format('YYYY-MM-DD HH:mm');
    const annontation = ENABLE_PARTNERSHIPS ? `MRL partnerstwa ${now}` : `MRL gminy ${now}`;

    return (
      <Plot
        className="plot__fullsize"
        data={[
          {
            type: 'bar',
            text: valuesStr1,
            textposition: 'outside',
            name: years[0]?.toString(),
            ...indicatorValues1,
            hovertemplate: `Wartość: %{y:.2f}<extra>${years[0]}</extra>`,
            width: 0.15,
          },
          {
            type: 'bar',
            text: valuesStr2,
            textposition: 'outside',
            name: years[1]?.toString(),
            ...indicatorValues2,
            hovertemplate: `Wartość: %{y:.2f}<extra>${years[1]}</extra>`,
            width: 0.15,
          },
          {
            type: 'bar',
            text: valuesStr3,
            textposition: 'outside',
            name: years[2]?.toString(),
            ...indicatorValues3,
            hovertemplate: `Wartość: %{y:.2f}<extra>${years[2]}</extra>`,
            width: 0.15,
          },
          {
            type: 'bar',
            text: valuesStr4,
            textposition: 'outside',
            name: years[3]?.toString(),
            ...indicatorValues4,
            hovertemplate: `Wartość: %{y:.2f}<extra>${years[3]}</extra>`,
            width: 0.15,
          },
          {
            type: 'bar',
            text: valuesStr5,
            textposition: 'outside',
            name: years[4]?.toString(),
            ...indicatorValues5,
            hovertemplate: `Wartość: %{y:.2f}<extra>${years[4]}</extra>`,
            width: 0.15,
          },
        ]}
        layout={{
          autosize: true,
          width: dimension === 'OVERALL' ? 1200 : 800,
          margin: { t: 10, b: 40 },
          legend: { xanchor: 'left', x: 0, orientation: 'v' },
          hoverlabel: { namelength: 0 },
          xaxis: {
            tickmode: 'linear',
            title: { text: `<i>${annontation}</i>`, standoff: 45, font: { size: 9 } },
          },
        }}
      />
    );
  };

  const getTable = (data: AreaValue[] | undefined) => {
    if (!data) {
      return <div />;
    }

    const areas: number[] = _.chain(data).sortBy('area.order').map('area.key').uniq().value();
    const years = _.chain(data).map('year').uniq().sort().value();

    const renderName = (text: string, record: TableRow) => {
      return <Link to={`/areas/${record.key}`}>{text}</Link>;
    };

    const columns = [
      {
        title: 'Nazwa',
        dataIndex: 'name',
        render: renderName,
      },
      ...years.map((year) => ({
        title: year,
        dataIndex: year,
        render: NumberFormatter,
      })),
    ];

    const columnData = [
      ...areas.map((areaKey) => ({
        key: areaKey,
        name: (_.find(data, (a) => a.area.key === areaKey) as AreaValue)?.area.label,
        ..._.zipObject(
          _.map(
            _.filter(data, (a) => a.area.key === areaKey),
            'year',
          ),
          _.map(
            _.filter(data, (a) => a.area.key === areaKey),
            'value',
          ),
        ),
      })),
    ];

    return (
      <div className="table-container">
        <Table size="small" loading={isLoading} pagination={false} columns={columns} dataSource={columnData} bordered />
      </div>
    );
  };

  const title = useMemo(
    () => (
      <React.Fragment>
        <p>{'Wskaźnik rozwoju w latach (obszary)'}</p>
        {data?.includes_copied_values ? <CopyWarningIcon /> : null}
      </React.Fragment>
    ),
    [data],
  );

  return <ChartContainer title={title} chart={getChart(data?.data)} table={getTable(data?.data)} />;
}
