import React, { ReactNode } from 'react';
import { StandardizedValueDataRow } from '../interfaces/indicators.interface';
import _ from 'lodash';
import Plot from 'react-plotly.js';
import { DecimalNumberFormatter } from '../formatting';
import { Button, Table } from 'antd';
import ChartContainer from '../../shared/components/ChartContainer';
import { ENABLE_PARTNERSHIPS } from '../../config';
import { api_url } from '../../queries';
import moment from 'moment';

interface Props {
  data?: StandardizedValueDataRow[];
  title: string | ReactNode;
  label: string;
  isLoading: boolean;
  dimension?: string;
  switchType?: boolean;
  precision?: number;
}

export default function StandardizedValueChart({
  data,
  label,
  isLoading,
  title,
  dimension,
  switchType,
  precision = 2,
}: Props) {
  const now = moment().format('YYYY-MM-DD HH:mm');
  const annontation = ENABLE_PARTNERSHIPS ? `MRL partnerstwa ${now}` : `MRL gminy ${now}`;

  const chartlabel =
    ENABLE_PARTNERSHIPS && switchType == undefined
      ? `Ogólny Wskaźnik Rozwoju Gminy`
      : ENABLE_PARTNERSHIPS && switchType
        ? `Ogólny Wskaźnik Rozwoju Partnerstwa`
        : `Ogólny Wskaźnik Rozwoju Gminy`;
  const labelUpdated =
    ENABLE_PARTNERSHIPS && switchType == undefined
      ? 'Gmina: ' + label
      : ENABLE_PARTNERSHIPS && switchType
        ? 'Partnerstwo: ' + label
        : label;
  const dimensions = [
    { key: 'OVERALL', label: chartlabel },
    { key: 'ECONOMIC', label: 'Wymiar Gospodarczy' },
    { key: 'SOCIAL', label: 'Wymiar Społeczny' },
    { key: 'ENVIRONMENTAL', label: 'Wymiar Środowiskowy' },
  ];
  const chartTitle = dimensions.find((el) => el.key === dimension)?.label || dimension;

  function multipleStringLines(title: string) {
    // check if greater than threshold!
    if (title.length > 100) {
      //break into words
      const y_axis = title.split(' ');
      //2-lines
      const interval = title.split(' ').length / 2;
      return y_axis.slice(0, interval).join(' ') + '<br>' + y_axis.slice(interval, y_axis.length).join(' ');
    }
    return title;
  }

  const getChart = (data: StandardizedValueDataRow[] | undefined) => {
    if (!data) {
      return <div />;
    }
    // limit the number of years to show up to 5 latest years
    if (data.length > 5) {
      const diff = data.length - 5;
      data.splice(0, diff);
    }
    const indicatorValues = {
      x: _.map(data, 'year'),
      y: _.map(data, 'value'),
    };
    const valuesStr = indicatorValues.y.map((e) =>
      (Math.round((e + Number.EPSILON) * 10 ** precision) / 10 ** precision).toFixed(precision),
    );

    return (
      <Plot
        className="plot__fullsize"
        data={[
          {
            type: 'bar',
            name: labelUpdated,
            width: 0.5,
            text: valuesStr,
            textposition: 'outside',
            hovertemplate: `%{y:.${precision}f}<extra></extra>`,
            ...indicatorValues,
            marker: {
              color: '#1890ff',
              opacity: 0.7,
            },
            cliponaxis: false,
          },
        ]}
        layout={{
          autosize: true,
          showlegend: ENABLE_PARTNERSHIPS ? true : false,
          title: multipleStringLines(typeof chartTitle === 'string' ? chartTitle : ''),
          transition: {
            duration: 1000,
            easing: 'back-in-out',
          },
          width: 800,
          height: 480,
          margin: { t: 70 },
          legend: { xanchor: 'center', x: 0.5, orientation: 'h' },
          xaxis: {
            tickmode: 'linear',
            title: { text: `<i>${annontation}</i>`, font: { size: 9 }, standoff: 15 },
          },
          yaxis: { automargin: true },
        }}
      />
    );
  };

  const getTable = (data: StandardizedValueDataRow[] | undefined) => {
    if (!data) {
      return <div />;
    }

    const columns = [
      {
        title: 'Nazwa',
        dataIndex: 'name',
      },
      ...(data &&
        data?.map((value: StandardizedValueDataRow) => ({
          title: value.year,
          dataIndex: value.year,
          render: (text: string) => DecimalNumberFormatter(text, precision),
        }))),
    ];

    const columnData = [
      {
        name: label,
        key: 'value',
        label: 'Wartość',
        ..._.zipObject(_.map(data, 'year'), _.map(data, 'value')),
      },
    ];

    const exportXlsx = () => {
      const file_name = convertToSlug(label);
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          data: data,
          label: label,
          filename: chartlabel,
          sheet_name: convertToSlug(chartTitle || 'Wskażnik rozwoju'),
          columns: columns,
        }),
      };

      return fetch(`${api_url}chart_table_export/`, requestOptions)
        .then((response) => {
          return response.blob();
        })
        .then((blob) => {
          const href = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', file_name);
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => console.error(err));
    };

    function convertToSlug(Text: string) {
      Text = Text.replace('.', '_').toLowerCase();
      const from = 'ãàáäâęẽèéëêìíïîõòóöôùúüûñçćśłż·/,:;';
      const to = 'aaaaaeeeeeeiiiiooooouuuunccslz-----';
      for (let i = 0, l = from.length; i < l; i++) {
        Text = Text.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }

      return Text.replace(/ /g, '-').replace(/[^\w-]+/g, '');
    }

    const style = {
      width: '100%',
      marginTop: '-15px',
    };

    return (
      <div style={style}>
        <div className="table-container">
          <Button size="small" onClick={exportXlsx}>
            Pobierz XLSX
          </Button>
        </div>
        <div className="table-container">
          <Table
            size="small"
            loading={isLoading}
            pagination={false}
            columns={columns}
            dataSource={columnData}
            bordered
            scroll={{ y: 400 }}
          />
        </div>
      </div>
    );
  };

  if (isLoading || !data) {
    return null;
  }

  return <ChartContainer title={title} chart={getChart(data)} table={getTable(data)} />;
}
