import NumberFormat from 'react-number-format';
import React from 'react';

export const NumberFormatter = (text: string) => (
  <NumberFormat
    decimalScale={2}
    fixedDecimalScale
    value={parseFloat(text)}
    displayType={'text'}
    thousandSeparator={' '}
    decimalSeparator={','}
  />
);

export const DecimalNumberFormatter = (text: string, precision = 2) => (
  <NumberFormat
    decimalScale={precision}
    fixedDecimalScale
    value={parseFloat(text)}
    displayType={'text'}
    thousandSeparator={' '}
    decimalSeparator={','}
  />
);
