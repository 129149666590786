import React, { useMemo } from 'react';
import Plot from 'react-plotly.js';
import ChartContainer from '../../shared/components/ChartContainer';
import { State, useConfigurationStore } from '../../store';
import { useQuery } from 'react-query';
import { fetchMainAreaValue } from '../../queries';
import { AreaValue, IndicatorAreaDimension } from '../../shared/interfaces/indicators.interface';
import _ from 'lodash';
import { Table } from 'antd';
import './indicatorView.shared.scss';
import { NumberFormatter } from '../../shared/formatting';
import { Link } from 'react-router-dom';
import { DYNAMICS_RANGE, ENABLE_PARTNERSHIPS } from '../../config';
import moment from 'moment';
import CopyWarningIcon from '../../shared/components/CopyWarningIcon/CopyWarningIcon';

interface TableRow {
  key: number;
  name: string;
}

interface Props {
  dimension: IndicatorAreaDimension;
}

export default function IndicatorAreaChart({ dimension }: Props) {
  const territory = useConfigurationStore((state: State) => state.territory);
  const partnership = useConfigurationStore((state: State) => state.partnership);

  const reference_territory = ENABLE_PARTNERSHIPS ? partnership : territory;

  const year = useConfigurationStore((state: State) => state.year);
  const { isLoading, data } = useQuery(
    ['fetchMainAreaValue', reference_territory, year, dimension],
    fetchMainAreaValue,
    {
      enabled: !!reference_territory,
    },
  );

  const getChart = (data: AreaValue[] | undefined) => {
    if (!data) {
      return <div />;
    }

    const yearData = _.chain(data)
      .filter({ year: year })
      .sortBy((row) => row.area.order)
      .value();

    const indicatorValues = {
      r: _.map(yearData, 'value'),
      theta: _.map(yearData, 'area.label'),
    };
    indicatorValues.r = indicatorValues.r.concat(indicatorValues.r[0]);
    indicatorValues.theta = indicatorValues.theta.concat(indicatorValues.theta[0]);

    const previousYearData = _.chain(data)
      .filter({ year: year - DYNAMICS_RANGE })
      .sortBy((row) => row.area.order)
      .value();
    const indicatorPreviousValues = {
      r: _.map(previousYearData, 'value'),
      theta: _.map(previousYearData, 'area.label'),
    };
    indicatorPreviousValues.r = indicatorPreviousValues.r.concat(indicatorPreviousValues.r[0]);
    indicatorPreviousValues.theta = indicatorPreviousValues.theta.concat(indicatorPreviousValues.theta[0]);

    const now = moment().format('YYYY-MM-DD HH:mm');
    const annontation = ENABLE_PARTNERSHIPS ? `MRL partnerstwa ${now}` : `MRL gminy ${now}`;

    return (
      <Plot
        className="plot__fullsize"
        data={[
          {
            type: 'scatterpolar',
            fill: 'toself',
            fillcolor: 'rgba(55, 188, 155, 0.5)',
            name: `${year}`,
            ...indicatorValues,
            hovertemplate: `%{theta}<br>Wartość: %{r:.2f}`,
            marker: {
              color: 'rgb(55, 188, 155)',
              opacity: 1,
              size: 8,
            },
            opacity: 0.8,
          },
          {
            type: 'scatterpolar',
            fill: 'toself',
            fillcolor: 'rgba(150, 122, 220, 0.5)',
            name: `${year - DYNAMICS_RANGE}`,
            ...indicatorPreviousValues,
            hovertemplate: `%{theta}<br>Wartość: %{r:.2f}`,
            marker: {
              color: 'rgb(150, 122, 220)',
              size: 8,
              opacity: 1,
            },
            opacity: 0.8,
          },
        ]}
        layout={{
          autosize: true,
          title: { text: `<i>${annontation}</i>`, yanchor: 'top', y: 0.02, font: { size: 9 } },
          margin: { t: 20, b: 50 },
          polar: {
            radialaxis: {
              type: 'linear',
              angle: 90,
              nticks: 5,
              color: 'rgba(68,68,68, 0.5)',
            },
            angularaxis: {
              type: 'category',
              // @ts-ignore
              direction: 'clockwise',
              ticks: '',
              color: 'rgba(68,68,68, 0.7)',
              tickfont: {
                family: '"Poppins", sans-serif',
                size: 15,
              },
            },
          },
        }}
      />
    );
  };

  const getTable = (data: AreaValue[] | undefined) => {
    if (!data) {
      return <div />;
    }

    const areas: number[] = _.chain(data).sortBy('area.order').map('area.key').uniq().value();
    const years = _.chain(data).map('year').uniq().sort().value();

    const renderName = (text: string, record: TableRow) => {
      return <Link to={`/areas/${record.key}`}>{text}</Link>;
    };

    const columns = [
      {
        title: 'Nazwa',
        dataIndex: 'name',
        render: renderName,
      },
      ...years.map((year) => ({
        title: year,
        dataIndex: year,
        render: NumberFormatter,
      })),
    ];

    const columnData = [
      ...areas.map((areaKey) => ({
        key: areaKey,
        name: (_.find(data, (a) => a.area.key === areaKey) as AreaValue)?.area.label,
        ..._.zipObject(
          _.map(
            _.filter(data, (a) => a.area.key === areaKey),
            'year',
          ),
          _.map(
            _.filter(data, (a) => a.area.key === areaKey),
            'value',
          ),
        ),
      })),
    ];

    return (
      <div className="table-container">
        <Table size="small" loading={isLoading} pagination={false} columns={columns} dataSource={columnData} bordered />
      </div>
    );
  };

  const title = useMemo(
    () => (
      <React.Fragment>
        <p>{'Wskaźnik rozwoju w obszarach na początku i końcu badanego okresu'}</p>
        {data?.includes_copied_values ? <CopyWarningIcon /> : null}
      </React.Fragment>
    ),
    [data],
  );

  return <ChartContainer title={title} chart={getChart(data?.data)} table={getTable(data?.data)} />;
}
