import React, { useMemo } from 'react';
import { State, useConfigurationStore } from '../../store';
import { useQuery } from 'react-query';
import { fetchMainValue } from '../../queries';
import { IndicatorAreaDimension } from '../../shared/interfaces/indicators.interface';
import './indicatorView.shared.scss';
import StandardizedValueChart from '../../shared/components/StandarizedValueChart';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

interface Props {
  dimension: IndicatorAreaDimension;
}

export default function PartnershipChart({ dimension }: Props) {
  const partnership = useConfigurationStore((state: State) => state.partnership);
  const year = useConfigurationStore((state: State) => state.year);
  const { isLoading, data } = useQuery(['mainValue', partnership, year, dimension], fetchMainValue, {
    enabled: !!partnership,
  });
  const dimensions = [
    {
      key: 'OVERALL',
      label: `Wskaźnik rozwoju partnerstwa - ogólny`,
      tooltip:
        'Wartością syntetyczną dla partnerstwa jest suma odpowiednich wartości wskaźników dla gmin wchodzących w skład partnerstwa; dla uwzględnienia zróżnicowania gmin w partnerstwie wartość syntetycznego wskaźnika dla partnerstwa jest średnią ważoną uwzględniającą liczbę mieszkańców poszczególnych gmin.',
    },
    {
      key: 'ECONOMIC',
      label: 'Wskaźnik rozwoju partnerstwa - wymiar gospodarczy',
      tooltip:
        'Wartością syntetyczną dla partnerstwa jest suma odpowiednich wartości wskaźników diagnostycznych wymiaru gospodarczego dla gmin wchodzących w skład partnerstwa; dla uwzględnienia zróżnicowania gmin w partnerstwie wartość syntetycznego wskaźnika dla partnerstwa jest średnią ważoną uwzględniającą liczbę mieszkańców poszczególnych gmin. ',
    },
    {
      key: 'SOCIAL',
      label: 'Wskaźnik rozwoju partnerstwa - wymiar społeczny',
      tooltip:
        'Wartością syntetyczną dla partnerstwa jest suma odpowiednich wartości wskaźników diagnostycznych wymiaru społecznego dla gmin wchodzących w skład partnerstwa; dla uwzględnienia zróżnicowania gmin w partnerstwie wartość syntetycznego wskaźnika dla partnerstwa jest średnią ważoną uwzględniającą liczbę mieszkańców poszczególnych gmin. ',
    },
    {
      key: 'ENVIRONMENTAL',
      label: 'Wskaźnik rozwoju partnerstwa - wymiar środowiskowy',
      tooltip:
        'Wartością syntetyczną dla partnerstwa jest suma odpowiednich wartości wskaźników diagnostycznych wymiaru środowiskowego dla gmin wchodzących w skład partnerstwa; dla uwzględnienia zróżnicowania gmin w partnerstwie wartość syntetycznego wskaźnika dla partnerstwa jest średnią ważoną uwzględniającą liczbę mieszkańców poszczególnych gmin. ',
    },
  ];
  const chartTitle = dimensions.find((el) => el.key === dimension)?.label || dimension;
  const tooltip = dimensions.find((el) => el.key === dimension)?.tooltip || dimension;

  const title = useMemo(
    () => (
      <React.Fragment>
        <p>{chartTitle}</p>
        <Tooltip title={tooltip} placement="top">
          <InfoCircleOutlined />
        </Tooltip>
      </React.Fragment>
    ),
    [data],
  );

  return (
    <StandardizedValueChart
      data={data?.data}
      label={partnership?.label || ''}
      isLoading={isLoading}
      title={title}
      dimension={dimension}
      switchType={true}
    />
  );
}
