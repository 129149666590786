import create, { SetState } from 'zustand';
import { Territory } from './shared/interfaces/territory.interface';
import { availableYears } from './config';

export type State = {
  partnership: Territory | undefined;
  territory: Territory | undefined;
  comparisonTerritories: number[];
  year: number;
  setPartnership(value: Territory | undefined): void;
  setTerritory(value: Territory | undefined): void;
  setComparisonTerritories(value: number[]): void;
  setYear(value: number): void;
};

export const useConfigurationStore = create<State>((set: SetState<State>) => ({
  partnership: undefined,
  territory: undefined,
  comparisonTerritories: [],
  year: availableYears[0],
  setPartnership: (partnership: Territory) => set(() => ({ partnership })),
  setTerritory: (territory: Territory) => set(() => ({ territory })),
  setComparisonTerritories: (comparisonTerritories: number[]) => set(() => ({ comparisonTerritories })),
  setYear: (year: number) => set(() => ({ year })),
}));
