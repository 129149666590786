import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Plot from 'react-plotly.js';
import ChartContainer from '../../shared/components/ChartContainer';
import { State, useConfigurationStore } from '../../store';
import { useQuery } from 'react-query';
import { fetchIndicatorData } from '../../queries';
import { Indicator, IndicatorData, IndicatorValue } from '../../shared/interfaces/indicators.interface';
import _ from 'lodash';
import { Table } from 'antd';
import './indicatorView.shared.scss';
import { DecimalNumberFormatter, NumberFormatter } from '../../shared/formatting';
import { ENABLE_PARTNERSHIPS } from '../../config';
import moment from 'moment';
import CopyWarningIcon from '../../shared/components/CopyWarningIcon/CopyWarningIcon';

type CellAlignment = 'left' | 'right' | 'center' | undefined;
interface Props {
  indicator?: Indicator;
}

const IndicatorDetailsChart: React.FC<Props> = ({ indicator }: Props) => {
  const now = moment().format('YYYY-MM-DD HH:mm');
  const annontation = ENABLE_PARTNERSHIPS ? `MRL partnerstwa ${now}` : `MRL gminy ${now}`;

  const { id } = useParams<{ id: string }>();
  const territory = useConfigurationStore((state: State) => state.territory);
  const year = useConfigurationStore((state: State) => state.year);
  const { isLoading, data } = useQuery(['indicatorDetails', id, territory, year], fetchIndicatorData, {
    enabled: !!territory && !!id,
  });

  const getChart = (data: IndicatorData | undefined) => {
    if (!data) {
      return <div />;
    }
    const indicatorValues = {
      x: _.map(data?.values, 'year'),
      y: _.map(data?.values, 'value'),
    };
    const valuesStr = indicatorValues.y.map((e) => Number(e).toString());

    const indicatorAvgValues = {
      x: _.map(data?.avg, 'year'),
      y: _.map(data?.avg, 'avg'),
    };
    const avgValuesStr = indicatorAvgValues.y.map((e) => Number(e).toString());

    const indicatorMedianValues = {
      x: _.map(data?.median, 'year'),
      y: _.map(data?.median, 'value'),
    };

    function multipleStringLines(title: string) {
      // check if greater than threshold!
      if (title.length > 100) {
        //break into words
        const y_axis = title.split(' ');
        //2-lines
        const interval = title.split(' ').length / 2;
        return y_axis.slice(0, interval).join(' ') + '<br>' + y_axis.slice(interval, y_axis.length).join(' ');
      }
      return title;
    }

    return (
      <Plot
        className="plot__fullsize"
        data={[
          {
            type: 'bar',
            text: valuesStr,
            textposition: 'outside',
            name: territory?.label,
            ...indicatorValues,
            cliponaxis: false,
            hovertemplate: '%{y}<extra></extra>',
          },
          {
            type: 'bar',
            text: avgValuesStr,
            textposition: 'outside',
            name: 'Średnia w grupie porównawczej',
            ...indicatorAvgValues,
            cliponaxis: false,
            hovertemplate: '%{y}<extra></extra>',
          },
          {
            type: 'scatter',
            name: 'Mediana w grupie porównawczej',
            ...indicatorMedianValues,
          },
        ]}
        layout={{
          autosize: true,
          title: {
            text: multipleStringLines(typeof indicator?.label === 'string' ? indicator?.label : ''),
          },
          width: 800,
          margin: { t: 60 },
          legend: { xanchor: 'center', x: 0.5, orientation: 'h' },
          hoverlabel: { namelength: 0 },
          xaxis: { tickmode: 'linear', title: { text: `<i>${annontation}</i>`, font: { size: 9 }, standoff: 65 } },
          yaxis: { automargin: true },
        }}
      />
    );
  };

  const getTable = (data: IndicatorData | undefined) => {
    if (!data) {
      return <div />;
    }
    const columns = [
      {
        title: 'Nazwa',
        dataIndex: 'name',
        colSpan: 2,
        align: 'left' as CellAlignment,
        // eslint-disable-next-line
        render: (value: any, row: any, index: number) => {
          const obj = {
            children: value,
            props: {
              rowSpan: 0,
            },
          };
          if (index === 0) {
            obj.props.rowSpan = 3;
          }
          if (index === 3) {
            obj.props.rowSpan = 2;
          }
          return obj;
        },
      },
      {
        title: undefined,
        dataIndex: 'label',
        colSpan: 0,
        align: 'left' as CellAlignment,
      },
      ...(data &&
        data?.values.map((value: IndicatorValue) => ({
          key: value.year,
          title: value.year,
          dataIndex: value.year,
          align: 'right' as CellAlignment,
          render: (text: string, row: any) =>
            ['standarized-value', 'dynamic'].includes(row.key)
              ? NumberFormatter(text)
              : DecimalNumberFormatter(text, indicator?.precision),
        }))),
    ];

    const columnData = [
      {
        name: territory?.label,
        key: 'value',
        label: 'Wartość wskaźnika',
        ..._.zipObject(_.map(data?.values, 'year'), _.map(data?.values, 'value')),
      },
      {
        name: territory?.label,
        key: 'standarized-value',
        label: 'Wartość standaryzowana',
        ..._.zipObject(_.map(data?.values, 'year'), _.map(data?.values, 'standarized_value')),
      },
      {
        name: territory?.label,
        key: 'dynamic',
        label: 'Zmiana wartości standaryzowanej',
        ..._.zipObject(_.map(data?.dynamic, 'year'), _.map(data?.dynamic, 'dynamic')),
      },
      {
        name: `Grupa porównawcza ${territory?.comparison_group?.code}`,
        key: 'avg',
        label: 'Średnia w grupie porównawczej',
        ..._.zipObject(_.map(data?.avg, 'year'), _.map(data?.avg, 'avg')),
      },
      {
        name: `Grupa porównawcza ${territory?.comparison_group?.code}`,
        key: 'median',
        label: 'Mediana w grupie porównawczej',
        ..._.zipObject(_.map(data?.median, 'year'), _.map(data?.median, 'value')),
      },
    ];

    return (
      <div className="table-container">
        <Table size="small" loading={isLoading} pagination={false} columns={columns} dataSource={columnData} bordered />
      </div>
    );
  };
  const titleText = ENABLE_PARTNERSHIPS
    ? 'Wartość wskaźnika w latach dla wybranej gminy w partnerstwie'
    : 'Wartość wskaźnika w latach';

  const title = useMemo(
    () => (
      <React.Fragment>
        <p>{titleText}</p>
        {data?.includes_copied_values ? <CopyWarningIcon /> : null}
      </React.Fragment>
    ),
    [data],
  );
  return <ChartContainer title={title} chart={getChart(data)} table={getTable(data)} />;
};

export default IndicatorDetailsChart;
