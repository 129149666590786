import React, { useState } from 'react';
import './home.scss';
import HomeModeSelect from './components/HomeModeSelect/HomeModeSelect';
import IndicatorMainChart from './IndicatorMainChart';
import IndicatorAreaChart from './IndicatorAreaChart';
import IndicatorAreaDynamicsChart from './IndicatorAreaDynamicsChart';
import Title from '../../shared/components/Title';
import { IndicatorAreaDimension } from '../../shared/interfaces/indicators.interface';
import { ENABLE_PARTNERSHIPS } from '../../config';
import PartnershipChart from './PartnershipChart';
import ExcelDownloadButton from '../../shared/components/ExcelDownloadButton';
import IndicatorAreaDynamicsGroupedChart from './IndicatorAreaDynamicsGroupedChart';

const Home: React.FC = () => {
  const [dimension, setDimension] = useState('OVERALL' as IndicatorAreaDimension);

  return (
    <div className="home-container">
      <div className="home-header">
        <div className="title-wrapper">
          <Title title={`Raport z analizy rozwoju ${ENABLE_PARTNERSHIPS ? 'partnerstwa' : 'gminy'}`} bold />
          <div className="ant-col ant-col-4 buttons">
            <ExcelDownloadButton queryParams={{ level: 'year', dimension }} />
          </div>
        </div>
        <HomeModeSelect selected={dimension} setSelected={setDimension} />
      </div>
      {ENABLE_PARTNERSHIPS && <PartnershipChart dimension={dimension} />}
      <IndicatorMainChart dimension={dimension} />
      <IndicatorAreaDynamicsChart dimension={dimension} />
      <IndicatorAreaDynamicsGroupedChart dimension={dimension} />
      <IndicatorAreaChart dimension={dimension} />
    </div>
  );
};

export default Home;
