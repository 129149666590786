import React, { useState } from 'react';
import { Select } from 'antd';
import _ from 'lodash';
import { Territory } from '../../../shared/interfaces/territory.interface';
import axios from 'axios';
import { api_url } from '../../../queries';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import useConstant from 'use-constant';
import { useAsync } from 'react-async-hook';
import { sortByLabel } from '../../../shared/utils';

const { Option } = Select;

const searchAPI = (value: string) => axios.get(`${api_url}gmina/?name=${value}`).then((res) => res.data as Territory[]);
const useGminaSearch = () => {
  const [value, setValue] = useState<string>('');

  const searchAPIDebounced = useConstant(() => AwesomeDebouncePromise(searchAPI, 300));

  const search = useAsync(
    async (value: string) => {
      if (value.length) {
        return searchAPIDebounced(value);
      }
      return [] as Territory[];
    },
    [value],
  );

  return {
    value,
    setValue,
    search,
  };
};

type Props = {
  onSelect(val: Territory | undefined): void;
  onClear(): void;
};

const TerritorySelect: React.FC<Props> = ({ onSelect, onClear }) => {
  const [data, setData] = useState([] as Territory[]);

  const { search } = useGminaSearch();

  const handleSearch = async (value: string) => {
    if (value) {
      try {
        const response = await search.execute(value);
        setData(response);
      } catch (error) {
        console.log('Error searching for gminas');
      }
    }
  };

  return (
    <Select
      loading={search.loading}
      allowClear={true}
      showSearch
      placeholder="Wpisz nazwę samorządu"
      optionFilterProp="children"
      filterOption={false}
      onSelect={(key) => onSelect(_.find(data, ['key', key]))}
      onSearch={handleSearch}
      onClear={() => {
        setData([]);
        onClear();
      }}
      dropdownClassName="config-dropdown"
    >
      {sortByLabel(data).map((row) => (
        <Option key={row.key} value={row.key}>
          {row.label}
        </Option>
      ))}
    </Select>
  );
};

export default TerritorySelect;
