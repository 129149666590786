import { WarningTwoTone } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';

const CopyWarningIcon: React.FC = () => {
  return (
    <Tooltip title="Brakujące wartości ostatniego roku zostały uzupełnione danymi z roku poprzedzającego.">
      <WarningTwoTone twoToneColor="#ff7875" />
    </Tooltip>
  );
};

export default CopyWarningIcon;
