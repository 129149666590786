import React, { ReactNode } from 'react';
import './title.scss';
import { WarningTwoTone } from '@ant-design/icons';
import { Tooltip } from 'antd';

interface Props {
  title: string | ReactNode;
  bold?: boolean;
  warningTooltipText?: string;
}

export default function Title({ title, bold = false, warningTooltipText }: Props) {
  return (
    <div className="ant-col ant-col-20">
      <h1 className={`pageTitle ${bold ? 'pageTitle__bold' : ''}`}>
        {title}
        {warningTooltipText && (
          <Tooltip title={warningTooltipText}>
            <WarningTwoTone twoToneColor="#ff7875" />
          </Tooltip>
        )}
      </h1>
    </div>
  );
}
