import React from 'react';
import { State, useConfigurationStore } from '../../store';
import { useQuery } from 'react-query';
import { fetchAreaStandardizedValue } from '../../queries';
import { useParams } from 'react-router-dom';
import StandardizedValueChart from '../../shared/components/StandarizedValueChart';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

export default function PartnershipValueChart(areaName?: { areaName: string | undefined }) {
  const { id } = useParams<{ id: string }>();
  const partnership = useConfigurationStore((state: State) => state.partnership);
  const year = useConfigurationStore((state: State) => state.year);
  const { isLoading, data } = useQuery(
    ['fetchAreaStandardizedValue', id, partnership, year],
    fetchAreaStandardizedValue,
    {
      enabled: !!partnership,
    },
  );

  const title = (
    <span>
      <Tooltip
        title="Wartością syntetyczną dla partnerstwa jest suma odpowiednich wartości wskaźników diagnostycznych wybranego obszaru dla gmin wchodzących w skład partnerstwa; dla uwzględnienia zróżnicowania gmin w partnerstwie wartość syntetycznego wskaźnika dla partnerstwa jest średnią ważoną uwzględniającą liczbę mieszkańców poszczególnych gmin."
        placement="top"
      >
        <InfoCircleOutlined />
        {' Wskaźnik rozwoju partnerstwa w obszarze'}
      </Tooltip>
    </span>
  );

  return (
    <StandardizedValueChart
      data={data?.data}
      label={partnership?.label || ''}
      isLoading={isLoading}
      title={title}
      switchType={true}
      dimension={areaName?.areaName}
    />
  );
}
