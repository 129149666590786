import React, { useEffect } from 'react';
import { Select } from 'antd';
import { useQuery } from 'react-query';
import { fetchTerritoryComparisonGroup } from '../../../queries';
import { Territory } from '../../../shared/interfaces/territory.interface';
import { sortByLabel } from '../../../shared/utils';

const { Option } = Select;

type Props = {
  onSelect(arr: number[]): void;
  territory?: Territory;
  value: number[];
};

const ComparisonTerritorySelect: React.FC<Props> = ({ onSelect, territory, value }) => {
  const { isLoading, data } = useQuery(
    ['fetchTerritoryComparisonGroup', territory?.key],
    fetchTerritoryComparisonGroup,
    {
      enabled: Boolean(territory),
    },
  );

  useEffect(() => {
    data && onSelect(data.map((territory) => territory.key));
  }, [data, onSelect]);

  return (
    <Select
      disabled={!territory}
      loading={isLoading}
      mode="multiple"
      allowClear={true}
      showSearch
      placeholder="z grupy porównawczej"
      optionFilterProp="children"
      filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      onChange={(value: number[]) => onSelect(value)}
      dropdownClassName="config-dropdown"
      maxTagTextLength={10}
      maxTagCount={3}
      value={value}
    >
      {sortByLabel(data || []).map((row) => (
        <Option key={row.key} value={row.key}>
          {row.label}
        </Option>
      ))}
    </Select>
  );
};

export default ComparisonTerritorySelect;
