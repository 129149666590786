import React from 'react';
import DynamicBubbleChart from './DynamicBubbleChart';
import DynamicTable from './DynamicTable';
import ChartContainer from '../ChartContainer';
import { BubbleChartDataRow } from '../../interfaces/indicators.interface';
import { sortByName } from '../../utils';
import { ReactNode } from 'react';
interface Props {
  title: string | ReactNode;
  titleText: string | '';
  data?: BubbleChartDataRow[];
  isLoading?: boolean;
  linkUrl?: string;
  showTeryt?: boolean;
  areaName?: { areaName: string | undefined };
  showLegend?: boolean;
  showCompGroup?: boolean;
}

export default function DynamicsChart({
  data,
  title,
  isLoading = false,
  linkUrl,
  showTeryt,
  titleText,
  areaName,
  showLegend,
  showCompGroup,
}: Props) {
  const sorted = sortByName(data || []);

  return (
    <ChartContainer
      title={title}
      chart={<DynamicBubbleChart data={sorted} showLegend={showLegend} showCompGroup={showCompGroup} />}
      table={
        <DynamicTable
          data={sorted}
          isLoading={isLoading}
          linkUrl={linkUrl}
          showTeryt={showTeryt}
          title={title}
          titleText={titleText}
          areaName={areaName}
        />
      }
    />
  );
}
