import React, { useMemo } from 'react';
import { State, useConfigurationStore } from '../../store';
import { useQuery } from 'react-query';
import './indicatorView.shared.scss';
import { fetchDynamicsValue } from '../../queries';
import DynamicsChart from '../../shared/components/DynamicsChart';
import { IndicatorAreaDimension } from '../../shared/interfaces/indicators.interface';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ENABLE_PARTNERSHIPS } from '../../config';
import CopyWarningIcon from '../../shared/components/CopyWarningIcon/CopyWarningIcon';

interface Props {
  dimension: IndicatorAreaDimension;
}

export default function IndicatorAreaDynamicsChart({ dimension }: Props) {
  const territory = useConfigurationStore((state: State) => state.territory);
  const year = useConfigurationStore((state: State) => state.year);
  const comparisonTerritories = useConfigurationStore((state: State) => state.comparisonTerritories);
  const { isLoading, data } = useQuery(
    ['areaDynamics', territory, comparisonTerritories, year, dimension],
    fetchDynamicsValue,
    {
      enabled: !!territory,
    },
  );
  const titleText = ` Pozycja gminy w ${ENABLE_PARTNERSHIPS ? 'partnerstwie' : 'grupie porównawczej'}`;
  const title = useMemo(() => {
    return (
      <React.Fragment>
        <p>{titleText}</p>
        <Tooltip title="Stan i zmiana wskaźnika w okresie 5 lat" placement="top">
          <InfoCircleOutlined />
        </Tooltip>
        {data?.includes_copied_values ? <CopyWarningIcon /> : null}
      </React.Fragment>
    );
  }, [data]);

  return (
    <DynamicsChart
      title={title}
      data={data?.data}
      isLoading={isLoading}
      showTeryt
      titleText={titleText}
      showCompGroup={true}
    />
  );
}
