import React from 'react';
import { Select } from 'antd';
import _ from 'lodash';
import { Territory } from '../../../shared/interfaces/territory.interface';
import { fetchPartnershipMembers } from '../../../queries';
import { useQuery } from 'react-query';
import { sortByLabel } from '../../../shared/utils';

const { Option } = Select;

type Props = {
  partnership?: Territory;
  onSelect(val: Territory | undefined): void;
  value?: Territory;
};

const TerritorySelect: React.FC<Props> = ({ partnership, onSelect, value }) => {
  const { isLoading, data } = useQuery(['fetchPartnershipMembers', partnership?.key], fetchPartnershipMembers, {
    enabled: Boolean(partnership),
  });

  return (
    <Select
      value={value?.key}
      disabled={!partnership}
      loading={isLoading}
      allowClear={true}
      showSearch
      placeholder="Wpisz nazwę gminy"
      optionFilterProp="children"
      filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      onSelect={(key) => onSelect(_.find(data, ['key', key]))}
      onClear={() => {
        onSelect(undefined);
      }}
      dropdownClassName="config-dropdown"
    >
      {sortByLabel(data || []).map((row) => (
        <Option key={row.key} value={row.key}>
          {row.label}
        </Option>
      ))}
    </Select>
  );
};

export default TerritorySelect;
