import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { State, useConfigurationStore } from '../../store';
import { useQuery } from 'react-query';
import { fetchAreaGminaDynamics } from '../../queries';
import DynamicsChart from '../../shared/components/DynamicsChart';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { ENABLE_PARTNERSHIPS } from '../../config';
import CopyWarningIcon from '../../shared/components/CopyWarningIcon/CopyWarningIcon';

export default function GminaDynamicsChart(areaName?: { areaName: string | undefined }) {
  const { id } = useParams<{ id: string }>();

  const territory = useConfigurationStore((state: State) => state.territory);
  const year = useConfigurationStore((state: State) => state.year);
  const comparisonTerritories = useConfigurationStore((state: State) => state.comparisonTerritories);
  const { isLoading, data } = useQuery(
    ['fetchAreaGminaDynamics', id, territory, comparisonTerritories, year],
    fetchAreaGminaDynamics,
    {
      enabled: !!territory && !!id,
    },
  );

  const titleText = ` Pozycja ${ENABLE_PARTNERSHIPS ? 'gminy w partnerstwie' : 'gminy w grupie porównawczej'}`;
  const title = useMemo(
    () => (
      <React.Fragment>
        <p> {titleText}</p>
        <Tooltip title="Stan i zmiana wskaźnika w okresie 5 lat" placement="top">
          <InfoCircleOutlined />
        </Tooltip>
        {data?.includes_copied_values ? <CopyWarningIcon /> : null}
      </React.Fragment>
    ),
    [data],
  );

  return (
    <DynamicsChart
      title={title}
      data={data?.data}
      isLoading={isLoading}
      showTeryt
      titleText={titleText}
      areaName={areaName}
      showCompGroup={true}
    />
  );
}
