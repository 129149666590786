import React from 'react';
import './App.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes';
import AppLayout from './components/App/AppLayout';
import { QueryClient, QueryClientProvider } from 'react-query';
import ScrollToTop from './shared/components/ScrollToTop';
import axios from 'axios';
// import { ReactQueryDevtools } from 'react-query-devtools';

const queryClient = new QueryClient();

// axios will set xsrf headers for us in all future requests
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <ScrollToTop />
        <AppLayout>
          <Routes />
        </AppLayout>
      </Router>
      {/*<ReactQueryDevtools initialIsOpen={false} />*/}
    </QueryClientProvider>
  );
};

export default App;
